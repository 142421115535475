@import "globals";

// Tablet
@media only screen and (min-width: 640px) and (max-width: 1200px) {
    body {
        // border: 1px solid green;
        padding-top: 56px !important;
        // Navigation
        .ltx-Navigation {
            // border: 1px solid red;
            height: 56px !important;
            position: fixed !important;
            top: 0 !important;
            width: 100vw !important;
            z-index: 1000 !important;

            .ltx_mobile_toggle {
                display: block;
                cursor: pointer;
                width: 56px;
                height: 56px;
                // background: red;
                position: absolute;
                top: 0;
                left: 0;
            }

            // hide elemnts
            .ltx_navigation__logo,
            .ltx_navigation__leftarticle,
            .ltx_navigation__rightarticle,
            .ltx-navigation__sectionsContainer .ltx-navigation__sections {
                display: none;
            }

            .ltx_navigation__sitelogo {
                position: absolute;
                top: 6px !important;
                left: 50px !important;
                transform: translateX(-50%) translateY(-50%);
                height: 40px !important;
                width: calc(100% - 100px) !important;
                margin-left: unset !important;
                transform: scale(1) !important;

                // border: 1px solid;
                > div {
                    max-height: 100%;
                }
                > a > div > div {
                    height: 40px;
                }
            }

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 48px;
                height: 55px;
                // border: 1px solid red;
                content: "";
                cursor: pointer;
                background: url("/icon_hamburger.svg") center no-repeat;
                background-size: 16px 9px;
            }
            &.ltx_Expanded::before {
                background: url("/icon_x.svg") center no-repeat;
            }
            &.ltx_Expanded {
                height: 100vh !important;
                > div:first-of-type {
                    height: 100vh;
                    background: white;
                }
                .ltx_navigation__logo {
                    display: block;
                    left: 50vw;
                    transform: translateX(-50%);
                    top: 70px;
                }
                .ltx-navigation__sectionsContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // background: white;
                    top: 200px;
                    position: absolute;
                    height: calc(100vh - 400px);
                    margin-top: 0;
                    gap: 48px;
                    .ltx-navigation__sections {
                        flex-direction: column;
                        display: flex;
                        a {
                            border: 0;
                            text-align: center;
                            // outline: 1px solid;
                            padding: 28px 28px;
                        }
                        padding-bottom: 48px;
                        border-bottom: 1px solid var(--c-b-darkgrey);
                    }
                    .ltx-navigation__sectionsExtras:nth-of-type(3) {
                        padding-left: 0;
                    }
                    > div:first-of-type {
                        width: unset;
                        opacity: 1 !important;
                    }
                    .ltx-navigation__sectionsExtras a.ltx_aboutus {
                        // padding: 10px;
                        // height: auto;
                        // right: 50vw;
                        // transform: translateX(2px);
                        // top: -201px;
                    }
                    .ltx-navigation__sections div.ltx_search {
                        margin: 0 auto;
                    }
                }
            }
            .ltx-navigation__sectionsContainer
                .ltx-navigation__sectionsExtras:nth-of-type(3) {
                display: block !important;
                position: fixed;
                top: 19px;
                right: -20px;
                a.ltx_aboutus {
                    font-size: 15px !important;
                }
            }
        }

        main {
            padding-top: 0;
        }

        main.ltx_Short {
            margin-top: 0;
            padding-top: 0;
        }

        // Homepage Threezones
        .ltx-ZoneThreeColumns {
            // border: 1px solid red;
            flex-direction: row;
            flex-wrap: wrap;
            & > div {
                order: 2;
            }
            & > div:nth-of-type(2) {
                // border: 1px solid blue;
                order: 1;
                flex-grow: 1;
                width: calc(100% - 2 * 48px);
                margin: 24px 48px;
                .ltx_zonethreecols_Image {
                    background-size: contain;
                }
                .ltx_zonethreecols_Label {
                    transform: translateY(-20px);
                }
                .txt_Header1 {
                    width: 80%;
                }
                .txt_BodyCopy1 {
                    width: 80%;
                }
            }
        }

        // ltx-BigArticleZone
        .ltx-BigArticleZone {
            margin: 0 48px;
            // border: 1px solid black;
            flex-direction: column;
            .ltx_Container {
                flex-direction: column;
                .ltx_Image {
                    width: 100%;
                    text-align: center;
                    height: 607px;
                    background-position: center;
                    background-size: cover;
                }
                & > div:nth-of-type(2) {
                    // border: 1px solid black;
                    width: 100%;
                    padding-bottom: 48px;
                }
            }
        }

        // QuoteZone
        .ltx-QuoteZone {
            padding-top: 2rem;
            padding-left: 48px;
            padding-right: 48px;
            .ltx_Container .ltx_Name {
                display: block;
                margin-left: 0;
            }
        }

        //ltx-GridZone
        .ltx-GridZone {
            .ltx_Container {
                flex-direction: column;
                align-items: center;
                .txt_HomePageTitle {
                    @include mixin_font(55, 60, -20, bold);
                }
            }
            .ltx_Container:nth-of-type(2) > div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 48px;
                width: unset;
                padding-top: 0;
            }
        }

        .ltx-ShortGridZone {
            .ltx_Container:nth-of-type(2) {
                // border: 1px solid red;
                flex-direction: column;
                .ltx_ArticleBox {
                    // border: 1px solid black;
                    flex-direction: row;
                    width: 100%;
                    margin-bottom: 0;
                    .ltx_Image {
                        width: 244px;
                        height: 244px;
                    }
                    > div:nth-of-type(2) {
                        // border: 1px solid;
                        padding: 0 15%;
                        display: flex;
                        flex-grow: 1;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
            .ltx_Container:nth-of-type(3) {
                margin-top: 29px;
            }
            padding-bottom: 60px;
        }
        // Wide article zone
        .ltx-WideArticleZone {
            padding: 48px;
        }
        // Subscribe
        .txt_Subscribe {
            padding: 0px 2 * 48px;
            text-align: center;
        }

        // Footer
        .ltx-Footer {
            padding-bottom: 10px;
            .ltx_Container {
                flex-direction: column;
                align-items: center;
                .ltx_footer__section {
                    display: none;
                }
                & > div:last-of-type {
                    // border: 1px solid white;
                    text-align: center;
                }
                .ltx_footer__logo {
                    margin-left: unset;
                }
            }
            .ltx_Container:last-of-type {
                // border: 1px solid blue;
                display: flex;
                flex-direction: row;
            }
        }

        // Section
        .ltx_SectionTitle {
            @include mixin_font(62, 80, -20, bold);
            height: 80px;
            padding: 0;
        }
        // Section Bottom Grid
        .ltx-SectionBottomGrid .ltx_Container:nth-of-type(2) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .ltx_CreatorCard {
                padding-top: 14px;
                .ltx_Image {
                    margin-top: 0 !important;
                }
            }
        }
        // .ltx-SectionBottomGrid .ltx_Container:last-of-type {
        //     grid-template-columns: 1fr;
        // }

        //Article
        .ltx-ArticleHeader,
        .ltx-CreatorHeader {
            // border: 1px solid red;
            background-position: 0px 320px;
            // height: 837px;
            height: calc(100vw / 2.2 + 320px);
            background-size: 100vw;

            .ltx_Container {
                flex-direction: column;
                padding-left: 0;
                height: unset;
                .ltx_LeftColumn {
                    height: auto;
                    width: 100%;
                    padding: 48px;
                    h1 {
                        // @include mixin_maxlines(2);
                    }
                    h3 {
                        width: 100%;
                        @include mixin_maxlines(2);
                    }
                }
            }
        }
        .ltx-Article.style_Four .ltx-ArticleHeader {
            margin-bottom: min(126vw, 810px);
        }

        .ltx-CreatorsHeader .ltx_Container .ltx_Title {
            margin: 0 2rem;
            width: calc(100% - 4rem);
        }

        .ltx-ArticleBody,
        .ltx-CreatorBody {
            .ltx_Container {
                flex-direction: column;
                width: 100%;
                margin: 0;
                .ltx_LeftColumn {
                    padding: 96px 48px 0 48px;
                    width: 100%;
                    margin: 0;
                }
                .ltx_RightColumn {
                    // border: 1px solid red;
                    margin: 0 48px;
                    padding-top: 190px;
                    background-size: cover;
                    background-position-y: 0;
                    aspect-ratio: 1/1.75;
                    .ltx_Holder {
                        width: 100%;
                        padding: 24px;
                        > div:last-child {
                            // border: 1px solid;
                            width: 100%;
                            gap: 24px;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            > .ltx_Article {
                                // border: 1px solid;
                                width: 100%;
                                .ltx_Image {
                                    // border: 1px solid;
                                    aspect-ratio: 1.623188405797101;
                                }
                                .ltx_Title {
                                    width: 60%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .ltx-CreatorBody
            .ltx_Container
            .ltx_RightColumn
            .ltx_Holder
            > div:last-child {
            display: flex;
            width: 224px;
            gap: unset;
        }

        .ltx-ArticleFooter {
            // border: 1px solid;
            padding-top: 0;
            margin-bottom: 48px;
            .ltx_Container {
                width: 366px;
                padding: 0;
                .ltx_Holder {
                    margin-top: 0;
                    .ltx_ArticlesBox {
                        // border: 1px solid;
                        flex-direction: column;
                    }
                }
            }
        }
        .ltx-CreatorFeatured {
            .ltx_Grid {
                grid-template-columns: 1fr;
                .ltx_ArticleBox > div:nth-of-type(2) {
                    flex-grow: 1;
                }
            }
        }
        .ltx-CreatorHeader .ltx_DotsContainer {
            left: 50vw;
            // bottom: -25px;
        }
        // Library
        .ltx-LibraryBody {
            padding: 48px;
            .ltx_Container {
                grid-template-columns: 1fr 1fr;
                justify-items: center;
            }
        }

        .ltx-Terms {
            padding: 4rem 3rem;
        }

        .ltx-FilterBar .ltx_Toggle_Filters::after {
            display: none;
        }

        .ltx_ArrowsContainer {
            top: unset;
            bottom: 9vw;
            left: 0;
            width: 100%;
        }

        .ltx-LibraryHeader .ltx_Container .ltx_Image {
            width: 80%;
        }

        .ltx-AboutUs {
            .ltx_Container:not(:last-of-type) {
                padding-left: 40px;
                padding-right: 40px;
                padding-bottom: 40px;
            }
            .ltx_LatestStories {
                .ltx_Container {
                    .ltx_SuggestedArticles {
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }

        .ltx-ArticleBody
            .ltx_Container
            .ltx_LeftColumn
            .ltx_BodyContainer
            h4
            a
            > div {
            left: unset;
            right: -6px;
            background-position: right 8px top 7px;
            padding-left: 15px;
            padding-right: 30px;
        }
    }
}

// Mobile
@media only screen and (max-width: 640px) {
    body {
        $nav_size: 50px;
        div > div.ltx_Loading::before {
            font-size: 3rem;
        }
        // border: 1px solid green;
        padding-top: $nav_size !important;
        // Navigation
        .ltx-Navigation {
            // border: 1px solid red;
            height: $nav_size !important;
            position: fixed !important;
            top: 0 !important;
            width: 100vw !important;
            z-index: 1000 !important;

            // hide elemnts
            .ltx_navigation__logo,
            .ltx_navigation__leftarticle,
            .ltx_navigation__rightarticle,
            .ltx-navigation__sectionsContainer .ltx-navigation__sections {
                display: none;
            }

            .ltx_navigation__sitelogo {
                position: absolute;
                top: 3px !important;
                left: 50px !important;
                transform: translateX(-50%) translateY(-50%);
                height: 40px !important;
                width: calc(100% - 100px) !important;
                margin-left: unset !important;
                transform: scale(1) !important;

                // border: 1px solid;
                > div {
                    max-height: 100%;
                }
                > a > div > div {
                    height: 40px;
                }
            }
            .ltx_navitaion__container {
                margin-top: 44px;
            }

            .ltx_mobile_toggle {
                display: block;
                cursor: pointer;
                width: 50px;
                height: 50px;
                // background: red;
                position: absolute;
                top: 0;
                left: 0;
            }

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 48px;
                height: $nav_size;
                // border: 1px solid red;
                content: "";
                cursor: pointer;
                background: url("/icon_hamburger.svg") center no-repeat;
                background-size: 16px 9px;
            }
            &.ltx_Expanded::before {
                background: url("/icon_x.svg") center no-repeat;
            }
            &.ltx_Expanded {
                overflow: inherit;
                position: relative;
                > div:first-of-type {
                    height: 100vh;
                    background: white;
                    margin-top: -3px;
                    margin-left: -1px;
                    width: 100vw;
                }
                .ltx_navigation__logo {
                    display: block;
                    left: 50vw;
                    transform: translateX(-50%);
                    top: 70px;
                }
                .ltx-navigation__sectionsContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // background: white;
                    top: 50vh;
                    position: absolute;
                    height: calc(100vh - 400px);
                    margin-top: 0;
                    transform: translateY(-50%);
                    gap: 48px;
                    transition: none;
                    .ltx-navigation__sections {
                        display: flex;
                        flex-direction: column;
                        a {
                            border: 0;
                            text-align: center;
                            // outline: 1px solid;
                            padding: 18px 28px;
                        }
                        padding-bottom: 48px;
                        border-bottom: 1px solid var(--c-b-darkgrey);
                    }
                    > div:first-of-type {
                        width: unset;
                        opacity: 1 !important;
                    }
                    .ltx-navigation__sectionsExtras:nth-of-type(3) {
                        padding: 0;
                        justify-content: center;
                    }
                    .ltx-navigation__sectionsExtras a.ltx_aboutus {
                        // padding: 10px;
                        // height: auto;
                        // right: 50vw;
                        transform: translateX(2px);
                        top: -201px;
                    }
                    .ltx_search {
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 10px;
                    }
                }
            }
            .ltx-navigation__sectionsContainer
                .ltx-navigation__sectionsExtras:nth-of-type(3) {
                display: block !important;
                position: fixed;
                top: 17px;
                right: -20px;
                a.ltx_aboutus {
                    font-size: 15px !important;
                }
            }
        }

        main {
            padding-top: 0;
        }

        main.ltx_Short {
            margin-top: calc(0px - var(--s-short-nav-height) - 5px);
        }

        // Homepage Threezones
        .ltx-ZoneThreeColumns {
            // border: 1px solid red;
            padding-top: 0;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0;
            & > div {
                order: 2;
            }
            & > div:nth-of-type(2) {
                // border: 1px solid blue;
                order: 1;
                flex-grow: 1;
                width: calc(100% - 2 * 48px);
                margin: 20px;
                padding: 0;
                .ltx_zonethreecols_Image {
                    background-size: contain;
                    margin: 10px;
                    width: calc(100% - 20px);
                }
                .ltx_zonethreecols_Label {
                    transform: translateY(-30px);
                    width: unset;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
                .txt_Header1 {
                    @include mixin_font(30, 35, -20, regular);
                    margin-top: -30px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .txt_BodyCopy1 {
                    width: 80%;
                }
            }
            & > div:first-of-type {
                padding: 30px;
                align-items: center;
                background-position: center bottom;
                a {
                    width: 100%;
                    .ltx_zonethreecols_LeftArticle {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 34px;

                        > .ltx_Label {
                            @include mixin_font(13, 24, 150, bold);
                        }
                        > .ltx_Image {
                            width: 100%;
                            height: 194px;
                            margin-bottom: 15px;
                        }
                        > .ltx_Title {
                            @apply px-8;
                            @include mixin_font(20, 24, -10, regular);
                        }
                        > .ltx_Announce {
                            display: none;
                        }
                    }
                }
            }
            & > div:last-of-type {
                // border: 1px solid;
                text-align: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                background-position: left 30px top 0px;
                background-size: cover;
                margin-bottom: 30px;
                padding-bottom: 110px;
                .ltx_zonethreecols_RightArticle {
                    > .ltx_Label {
                        @include mixin_font(13, 24, 150, bold);
                    }
                    > .ltx_Title {
                        width: 100%;
                        @include mixin_font(20, 24, -20, regular);
                    }
                    > .ltx_Announce {
                        @include mixin_font(15, 19, -10, bold);
                        margin-bottom: 8px;
                    }
                }
            }
        }

        .ltx-QuoteZone {
            padding-top: 30px;
            padding-bottom: 10px;
            .ltx_Container {
                .ltx_Label {
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
                .ltx_Quote {
                    @include mixin_font(32, 44, -20, bold);
                    margin-left: 2.5rem;
                    margin-right: 2.5rem;
                    // &::before {
                    //     margin-left: -17px;
                    // }
                }
                .ltx_Name {
                    @include mixin_font(26, 47, 100, bold);
                    display: block;
                    margin-left: 0;
                }
                .ltx_Image {
                    height: 70vw;
                    background-size: cover;
                    background-position: center;
                }
            }
        }

        .ltx-GridZone {
            padding: 40px 20px 0;
            .ltx_Container {
                .txt_HomePageTitle {
                    @include mixin_font(34, 38, -20, bold);
                    padding: 0 30px;
                }
                .ltx_ArticleBox {
                    &.ltx_Transparent {
                        background: white;
                    }
                }
            }

            .ltx_Container:nth-of-type(2) {
                // border: 1px solid;
                align-items: center;
                @apply flex-col;
                > div {
                    padding-top: 0 !important;
                }
                .ltx_ArticleBox:not(:first-of-type) {
                    // border: 1px solid red;
                    display: none;
                    .ltx_ArticleBox {
                        @apply flex-row;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        > div:first-child {
                            border: 1px solid blue;
                            width: 115px;
                            height: 115px;
                            flex-shrink: 0;
                            margin-right: 10px;
                        }
                        .ltx_Image {
                            border: 1px solid red !important;
                            width: 115px;
                            height: 115px;
                            background-size: cover;
                        }
                        .ltx_Title {
                            margin-top: 0;
                            margin-bottom: 0;
                            text-align: left;
                            @include mixin_font(18, 22, -10, bold);
                        }
                        .ltx_ArrowLink {
                            position: absolute;
                            bottom: 10px;
                            left: 143px;
                            display: inline-block;
                        }
                    }
                }
            }
            .ltx_Container > div:not(.txt_HomePageTitle) {
                width: auto;
            }
        }

        .ltx-ShortGridZone {
            // border: 1px solid;
            padding: 40px 20px;
            background-position: bottom;
            background-size: contain;
            .txt_HomePageTitle {
                @include mixin_font(34, 38, -20, bold);
            }

            .ltx_Container {
                align-items: center;
                @apply flex-col;
                gap: 0;
                padding-left: 0;
                padding-right: 0;
                .ltx_ArticleBox {
                    min-height: unset;

                    &.ltx_Transparent {
                        background: white;
                    }
                }
                .ltx_ArticleBox {
                    @apply flex-row;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    > div:first-child {
                        width: 115px !important;
                        height: 115px !important;
                        max-width: 115px !important;
                        max-height: 115px !important;
                        flex-shrink: 0 !important;
                        margin-right: 20px !important;
                    }
                    // border: 1px solid;
                    > div:not(.ltx_Image) {
                        @apply flex flex-col text-left;
                        flex-grow: 1;
                    }
                    .ltx_Label {
                        margin: 0;
                        margin-bottom: 5px;
                        text-align: left;
                    }
                    .ltx_Image {
                        width: 115px;
                        height: 115px;
                        background-size: cover;
                        margin-right: 20px;
                    }
                    .ltx_Title {
                        padding: 0;
                        margin-top: 0;
                        margin-bottom: 10px;
                        text-align: left;
                        @include mixin_font(18, 22, -10, bold);
                    }
                    .ltx_ArrowLink {
                        position: absolute;
                        bottom: -10px;
                        left: 143px;
                        display: block;
                        font-size: 0;
                    }
                }
                .ltx_Title {
                    min-height: 68px;
                }
                .ltx_Label {
                    padding-left: 0;
                }
            }
        }

        .ltx-WideArticleZone {
            // border: 1px solid red;
            padding: 30px;
            .ltx_Image {
                border-radius: 20px;
                overflow: hidden;
            }
            > .ltx_Container {
                .ltx_Title {
                    @include mixin_font(30, 35, -20, regular);
                    margin: 26px;
                }
                .ltx_Announce {
                    @include mixin_font(17, 24, -20, regular);
                    margin-bottom: 20px;
                }
            }
        }

        .ltx-Subscribe {
            // border: 1px solid red;
            background-size: 150vw;
            background-position: center;
            .txt_Subscribe {
                text-align: center;
                padding: 0 25px;
                @include mixin_font(30, 38, -20, bold);
            }
            > div:nth-of-type(2) {
                // border: 1px solid red;
                height: 70px;
            }
            form {
                // border: 1px solid red;
                max-width: 80vw;
                // overflow: hidden;
                label {
                    padding: 0 20px;
                }
                input {
                    @include mixin_font(14, 17, -20, regular);
                    padding-left: 20px;
                    max-width: 100%;
                    min-width: 100%;
                    padding-right: 90px;
                }
                button {
                    @include mixin_font(14, 17, -20, regular);
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        .ltx-Footer {
            .ltx_Container {
                flex-direction: column;
                align-items: flex-start;
                padding: 30px;
                > div:first-of-type {
                    // order: 2;
                    > a {
                        display: none;
                    }

                    > div {
                        margin: 0px 0 30px 0 !important;
                        width: calc(100vw - 80px);
                        justify-content: space-between;
                    }
                    .ltx_footer__logo {
                        // border: 1px solid red !important;
                        margin: 0 !important;
                    }
                }
                > div:last-of-type {
                    text-align: center;
                    flex-direction: column;
                    align-items: flex-start;
                    ul {
                        margin: 0;
                    }
                    a {
                        margin-top: 2.5rem;
                        margin-bottom: 2.5rem;
                    }
                }
                &:last-of-type {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        .ltx_SectionTitle {
            height: 60px;
            @include mixin_font(44, 48, -20, bold);
            padding-top: 10px;
        }

        .ltx-QuoteZone {
            .ltx_Container {
                .ltx_Label {
                    @include mixin_font(34, 48, -20, bold);
                }
            }
        }

        .ltx-SectionBottomGrid {
            // border: 1px solid;
            .ltx_Container {
                .ltx_Label {
                    &::before,
                    &::after {
                        display: none !important;
                    }
                }
            }

            .ltx_Container:nth-of-type(2) {
                // border: 1px solid red;
                @apply flex flex-col items-center;
                padding-left: 20px !important;
                padding-right: 20px !important;

                .ltx_CreatorCard {
                    @apply flex flex-row;
                    padding: 10px;
                    width: calc(100vw - 40px) !important;
                    position: relative;
                    margin-bottom: 0;
                    > div {
                        max-width: 40% !important;
                    }
                    .ltx_Image {
                        width: 108px;
                        height: 115px;
                        flex-shrink: 0;
                        margin-top: 0 !important;
                    }
                    .ltx_Label {
                        position: absolute;
                        top: 20px;
                        left: 160px;
                        width: calc(100% - 160px);
                        // border: 1px solid;
                        text-align: left;
                        padding-left: 0;
                        padding-right: 0;
                        @include mixin_font(13, 24, 150, bold);
                    }
                    .ltx_Title {
                        position: absolute;
                        top: 60px;
                        left: 160px;
                        width: calc(100% - 160px);
                        // border: 1px solid;
                        text-align: left;
                        @include mixin_font(18, 22, -10, regular);
                    }
                    .ltx_ArrowLink {
                        position: absolute;
                        bottom: 10px;
                        left: 130px;
                        width: calc(100% - 130px);
                        text-align: left;
                        align-items: flex-start;
                    }
                }
            }

            .ltx_Container:last-of-type {
                display: flex;
                align-items: center;
                padding-top: 30px;
                .ltx_ArrowLinkRed {
                    white-space: nowrap !important;
                    // border: 1px solid;
                }
            }
        }

        //Article
        .ltx-ArticleHeader,
        .ltx-CreatorHeader {
            // border: 1px solid blue;
            background-position: center 320px;
            // height: 490px;
            height: calc(100vw / 2.2 + 320px);
            background-size: 100vw;

            .ltx_Container {
                flex-direction: column;
                padding-left: 0;
                height: unset;
                .ltx_LeftColumn {
                    // min-height: 320px;
                    width: 100%;
                    padding: 30px;
                    h1 {
                        // @include mixin_maxlines(2);
                        @include mixin_font(30, 35, -10, bold);
                    }
                    h3 {
                        width: 100%;
                        // @include mixin_maxlines(2);
                        @include mixin_font(16, 22, -20, regular);
                        //margin-bottom: 0;
                        margin-bottom: 12px !important;
                    }
                    .ltx_Author {
                        @include mixin_font(12, 20, 150, bold);
                    }
                    .ltx_Date {
                        @include mixin_font(10, 20, 150, regular);
                    }
                }
            }
        }
        .ltx-CreatorsHeader {
            height: 250px !important;
            .ltx_Container .ltx_Title {
                left: unset;
                width: 100% !important;
                height: 50vw !important;
            }
        }
        .ltx-ArticleBody,
        .ltx-CreatorBody {
            .ltx_Container {
                flex-direction: column;
                width: 100%;
                margin: 0;
                .ltx_LeftColumn {
                    padding: 30px 30px 0 30px;
                    width: 100%;
                    margin: 0;
                    .ltx_BodyContainer h4 a > div {
                        left: unset;
                        right: -10px;
                        background-position: right 9px top 7px;
                        padding-left: 15px;
                        padding-right: 30px;
                    }
                    .ltx_Tags {
                        margin: 30px 0;
                    }
                }
                .ltx_RightColumn {
                    // border: 1px solid red;
                    margin: 0 0px;
                    padding-top: 90px;
                    padding-bottom: 60px;
                    padding-left: 10px;
                    padding-right: 10px;
                    background-size: cover;
                    background-position-y: 0;
                    aspect-ratio: 1/1.75;
                    width: 100vw;

                    .ltx_Holder {
                        width: 100%;
                        padding: 24px;
                        .ltx_Title {
                            @include mixin_font(29, 32, -10, bold);
                        }
                        > div:last-child {
                            // border: 1px solid;
                            width: 100%;
                            gap: 24px;
                            display: grid;
                            grid-template-columns: 1fr;
                            justify-items: center;
                            > .ltx_Article {
                                // border: 1px solid;
                                width: 100%;
                                position: relative;
                                align-items: flex-start;
                                padding-left: 160px;
                                // height: 100%;
                                min-height: 140px;
                                .ltx_Label {
                                    @include mixin_font(13, 24, 150, bold);
                                }
                                .ltx_Image {
                                    // border: 1px solid;
                                    position: absolute;
                                    width: 152px;
                                    left: 0;
                                    aspect-ratio: 1.623188405797101;
                                }
                                .ltx_Title {
                                    width: 100%;
                                    @include mixin_font(20, 24, -10, regular);
                                    text-align: left;
                                }
                                .ltx_ArrowLink {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .ltx-CreatorBody
            .ltx_Container
            .ltx_RightColumn
            .ltx_Holder
            > div:last-child {
            display: flex;
            justify-items: center;
            width: 224px;
            gap: unset;
        }
        .ltx-CreatorBody
            .ltx_Container
            .ltx_RightColumn
            .ltx_Holder
            > .ltx_Article {
            display: none;
        }
        .ltx-CreatorBody
            .ltx_Container
            .ltx_RightColumn
            .ltx_Holder
            > .ltx_Article:nth-child(2) {
            display: flex;
        }

        .ltx-ArticleFooter {
            // border: 1px solid;
            padding-top: 0;
            // margin-bottom: 48px;
            margin-top: 20px;
            .ltx_Container {
                padding: 20px;
                .ltx_Holder {
                    margin-top: 0;
                    .ltx_ArticlesBox {
                        // border: 1px solid;
                        flex-direction: column;
                        .ltx_Article {
                            .ltx_Label {
                                @include mixin_font(13, 20, 150, bold);
                            }
                        }
                    }
                }
            }
        }
        .ltx-CreatorFeatured {
            padding-bottom: 0;
            .ltx_Container {
                padding: 0;
                .ltx_Grid {
                    grid-template-columns: 1fr;
                    row-gap: 20px;
                    .ltx_ArticleBox {
                        padding: 10px;
                        > div:nth-of-type(1) {
                            flex-shrink: 1;
                        }
                        > div:nth-of-type(2) {
                            // flex-grow: 0;
                            align-items: flex-start;
                            width: 70%;
                            padding-left: 20px;
                            padding-right: 10px;
                        }
                        .ltx_Image {
                            width: 98px;
                            height: 115px;
                            margin-right: 10px;
                        }
                        .ltx_Label {
                            text-align: left;
                            padding-left: 0;
                            padding-right: 0;
                            @include mixin_font(13, 24, 150, bold);
                        }
                        .ltx_Title {
                            text-align: left;
                            padding: 0;
                            margin: 10px 0;
                            @include mixin_font(18, 22, -10, bold);
                        }
                        .ltx_ArrowLink {
                            width: unset;
                            margin: 0;
                            font-size: 0;
                            line-height: 0;
                        }
                    }
                }
            }
        }
        .ltx-CreatorHeader .ltx_DotsContainer {
            left: 50vw;
            // bottom: -25px;
        }

        .ltx-CreatorBody {
            .ltx_Container {
                .ltx_LeftColumn {
                    .ltx_BodyContainer {
                        .ltx_Bio {
                            @include mixin_font(17, 24, -10, bold);
                            > p {
                                @include mixin_font(17, 24, -10, bold);
                            }
                        }
                    }
                }
            }
        }

        .ltx-BigArticleZone {
            // border: 1px solid;
            padding-top: 0;
            padding-bottom: 0;
            .ltx_Container {
                flex-direction: column;
                .ltx_Image {
                    aspect-ratio: 1;
                    width: 100%;
                    height: unset;
                }
                > div {
                    width: 100%;
                }
                > div:last-of-type {
                    padding: 30px;
                    height: unset;
                    .ltx_Announce {
                        max-height: 160px;
                    }
                }
            }
        }
        // Library
        .ltx-LibraryHeader {
            .ltx_LibraryHeader_text {
                margin-top: 30px;
                margin-bottom: -10px;
            }
            .ltx_Container {
                width: 100vw;
            }
            .ltx_Container:nth-of-type(2) {
                // border: 1px solid red;
                padding: 0 30px;
            }
        }

        // FilterBar
        .ltx-FilterBar {
            height: unset;
            padding-top: 0;
            margin-top: -30px;
            .ltx_Container {
                flex-direction: column;
                padding: 20px 30px;
            }
            .ltx_Toggle_Filters {
                padding-left: 0;
                height: auto;
                &::after {
                    display: none;
                }
            }
            .ltx_FilterHolder {
                // border: 1px solid;
                padding: 20px 0 15px;
                > .ltx_Tag {
                    margin-left: 0px;
                    margin-right: 18px;
                }
            }
        }

        // LibraryBody
        .ltx-LibraryBody {
            // border: 1px solid green;
            padding: 30px;
            .ltx_Container {
                grid-template-columns: 1fr;
                .ltx_ArticleBox {
                    @apply flex-row;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    margin-bottom: 0;
                    min-height: unset;
                    > div:first-of-type {
                        max-width: 100px !important;
                        margin-right: 20px !important;
                    }
                    .ltx_Image {
                        width: 98px;
                        height: 115px;
                        background-size: cover;
                        margin-right: 10px;
                    }
                    > div:not(.ltx_Image) {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .ltx_Label {
                            margin: 0;
                            text-align: left;
                            @include mixin_font(13, 24, 150, bold);
                            padding: 0;
                        }
                        .ltx_Title {
                            margin-top: 0;
                            margin-bottom: 0;
                            text-align: left;
                            padding: 0;
                            @include mixin_font(18, 22, -10, bold);
                            @include mixin_maxlines(3);
                        }
                        .ltx_ArrowLink {
                            // position: absolute;
                            // bottom: 10px;
                            // left: 133px;
                            display: inline-block;
                            font-size: 0;
                            line-height: 0;
                        }
                    }
                }
            }
        }

        .ltx-CreatorsHeader {
            height: 380px;
            .ltx_Container {
                padding: 30px;
                .ltx_Title {
                    // border: 1px solid red;
                    left: 30px;
                    width: calc(100% - 60px);
                    height: 380px;
                }
            }
        }

        .ltx-Terms {
            padding: 4rem 2rem;
        }

        .ltx-SearchBar .ltx_Container {
            width: 90vw;
            .ltx_Results {
                h3 {
                    line-height: 1.3;
                }
                a {
                    line-height: 19px;
                    border-bottom: 1px solid #eee;
                    padding: 7px 0 5px;
                    span {
                        display: none;
                    }
                }
            }
        }

        .ltx-404 {
            padding: 1.5rem;
            .ltx_SuggestedArticles {
                flex-direction: column;
                .ltx_ArticleBox {
                    margin-bottom: 15px;
                }
            }
        }
        .ltx_ArrowsContainer {
            top: unset;
            bottom: 8vw;
            left: 0;
            width: 100%;
            padding: 0;
        }
        .ltx-FilterBar.ltx_Hidetags {
            padding: 5px 0 20px;
            .ltx_Container {
                // border: 1px solid red;
                display: flex;
                align-items: center;
                padding: 0;
            }
            .ltx_Toggle_Filters {
                // border: 1px solid blue;
                margin: 0;
                padding: 10px 0;
                text-align: center;
            }
        }
        .ltx-LibraryHeader .ltx_Container .ltx_Image {
            width: 90%;
        }
        .ltx_ShareButton_Holder {
            width: calc(100% - 50px);
        }
        .ltx-AboutUs {
            .ltx_Container:not(:last-of-type) {
                padding-left: 30px;
                padding-right: 30px;
                flex-direction: column-reverse;
                margin-bottom: 35px;
                &:first-of-type > div:first-of-type {
                    padding-top: 30px;
                }
            }

            .ltx_Container:first-of-type > div:first-of-type h1 {
                @include mixin_font(28, 32, -10, regular);
            }
            .ltx_Container:first-of-type > div:first-of-type h3 {
                @include mixin_font(22, 28, -10, regular);
            }
            .ltx_Container:not(:first-of-type) p,
            .ltx_Container:not(:first-of-type) ul {
                @include mixin_font(18, 24, -10, regular);
            }

            .ltx_Container:not(:first-of-type) ul {
                list-style-position: outside;
                padding-left: 20px;
            }

            .ltx_LatestStories {
                padding: 0;
                .ltx_Container {
                    .ltx_SuggestedArticles {
                        flex-direction: column;
                        align-items: center;
                    }
                    h2 {
                        margin: 20px 0;
                    }
                }
            }
        }
    }
}
