@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    // @apply bg-blue-100;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

:root {
    // Pallete
    --c-b-blue: #4cc7e1;
    --c-b-red: #ff504e;
    --c-b-pink: #ff91c1;
    --c-b-yellow: #fff820;
    --c-b-white: #ffffff;
    --c-b-darkgrey: #0c0e19;
    --c-b-black: #000000;
    --c-b-disabledgrey: #ccc;

    // Composed colors
    --c-red-xlight: #ffa7a6;
    --c-red-light: #ff9e9d;
    --c-red-normal: #ff8483;
    --c-red-normal: #ff738d;

    --c-pink-light: #ffb1d3;

    --c-blue-xlight: #c9eef6;
    --c-blue-mlight: #c8f2fb;
    --c-blue-light: #a7e3f0;
    --c-blue-normal: #6cd1e6;

    --c-yellow-light: #fff9c5;

    --c-grey-light: #c7c4c4;
    --c-grey-normal: #808285;
    --c-grey-dark: #414141;

    // Gradients
    --c-grad-green: linear-gradient(90deg, #fff9c1, #e2f5df 50%, #c8f2fb);
    --c-grad-blue: linear-gradient(90deg, #c8f2fb, #e2dced 50%, #ffc4dd);
    --c-grad-bluelight: linear-gradient(
        90deg,
        rgba(198, 241, 251, 0.6),
        rgba(227, 221, 238, 0.6) 50%,
        rgba(255, 194, 219, 0.6)
    );
    --c-grad-pink: linear-gradient(90deg, #ffc4dd, #ffddd0 50%, #fff9c1);
    --c-grad-pink-vertical: linear-gradient(
        180deg,
        #ffc4dd,
        #ffddd0 50%,
        #fff9c1
    );
    --c-grad-red: linear-gradient(90deg, #ff828c, #ffa3b4 50%, #ffc4dd);
    --c-grad-greyred: linear-gradient(90deg, #c8f2fb, #deb0b4 50%, #ee8183);
    --c-grad-yellowpurple: linear-gradient(90deg, #fff8c2, #fbd5e7);
    --c-grad-yellowpurple-vertical: linear-gradient(180deg, #fff8c2, #fbd5e7);

    // Font Variables
    --ff-default: BrownStd;
    --ff-special: Vesterbro;
    --fw-light: 300;
    --fw-regular: 500;
    --fw-bold: 700;

    --s-short-nav-height: 118px;
    --s-full-nav-height: 310px;
}

@font-face {
    font-family: BrownStd;
    font-weight: 700;
    src: url("fonts/BrownStd-Bold.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: BrownStd;
    font-weight: 300;
    font-style: italic;
    src: url("fonts/BrownStd-LightItalic.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: BrownStd;
    font-weight: 500;
    src: url("fonts/BrownStd-Regular.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: BrownStd;
    font-weight: 700;
    font-style: italic;
    src: url("fonts/BrownStd-BoldItalic.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: BrownStd;
    font-weight: 500;
    font-style: italic;
    src: url("fonts/BrownStd-Italic.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: MinionPro;
    font-weight: 500;
    src: url("fonts/MinionPro-Regular.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: Vesterbro;
    font-weight: 500;
    src: url("fonts/Vesterbro.ttf") format("truetype");
    font-display: swap;
}

/* NProgress minified css */
#nprogress {
    pointer-events: none;
}
#nprogress .bar {
    background: #29d;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0, -4px);
    -ms-transform: rotate(3deg) translate(0, -4px);
    transform: rotate(3deg) translate(0, -4px);
}
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}
#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
    position: absolute;
}
@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// Mixins
@mixin mixin_font($size, $line-height, $xdletterspacing, $weight) {
    font-family: var(--ff-default);
    font-size: #{$size}px;
    line-height: #{$line-height}px;
    // font-weight: $weight;
    letter-spacing: calc(1em * 0.001 * $xdletterspacing);

    @if $weight == "light" {
        font-weight: var(--fw-light);
    }
    @if $weight == "regular" {
        font-weight: var(--fw-regular);
    }
    @if $weight == "bold" {
        font-weight: var(--fw-bold);
    }
}

@mixin mixin_animation($property, $time: "") {
    -webkit-transition: $property 0.3s ease-in-out;
    -moz-transition: $property 0.3s ease-in-out;
    -o-transition: $property 0.3s ease-in-out;
    transition: $property 0.3s ease-in-out;

    @if $time != "" {
        -webkit-transition: $property $time ease-in-out;
        -moz-transition: $property $time ease-in-out;
        -o-transition: $property $time ease-in-out;
        transition: $property $time ease-in-out;
    }
}

@mixin mixin_xdletterspacing($xdletterspacing) {
    letter-spacing: calc(1em * 0.001 * $xdletterspacing);
}

@mixin mixin_maxlines($maxlines) {
    overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $maxlines;
    line-clamp: $maxlines;
    -webkit-box-orient: vertical;
}
// Normalizing Rem
body {
    font-size: 62.5%;
    color: var(--c-b-darkgrey);
} /* =10px */
// h1 { font-size: 2.4em; } /* =24px */

// Basic fundamental components
.txt_HomePageTitle {
    @include mixin_font(56, 62, -20, bold);
    color: var(--c-b-red);
}
.txt_ArticlePageTitle {
    @include mixin_font(29, 29, -10, bold);
    color: var(--c-b-red);
}
.txt_Header1 {
    @include mixin_font(42, 45, -10, regular);
}
.txt_Header2 {
    @include mixin_font(42, 45, -10, bold);
}
.txt_Header3 {
    @include mixin_font(36, 39, -10, regular);
}
.txt_Header4 {
    @include mixin_font(28, 32, -10, regular);
}
.txt_Header5 {
    @include mixin_font(20, 24, -20, regular);
}
.txt_Header6 {
    @include mixin_font(20, 24, -20, bold);
}
.txt_Header7 {
    @include mixin_font(18, 22, -20, bold);
}
.txt_Header8 {
    @include mixin_font(18, 22, -10, bold);
}
.txt_NavigationSection {
    @include mixin_font(17, 17, 150, bold);
}
.txt_NavigationSubscribe {
    @include mixin_font(16, 20, -10, bold);
}
.txt_ArticlesSubhead1 {
    @include mixin_font(22, 29, -20, regular);
}
.txt_ArticlesSubhead1 {
    @include mixin_font(22, 29, -20, regular);
}
.txt_ArticlesAuthor {
    @include mixin_font(14, 20, 150, bold);
    text-transform: uppercase;
}
.txt_ArticlesDate {
    @include mixin_font(13, 20, 150, regular);
}
.txt_ArticlesTag {
    @include mixin_font(13, 14, 0, bold);
}
.txt_BodyCopy1 {
    @include mixin_font(19, 26.5, -20, regular);
}
.txt_BodyCopy1_bold {
    @include mixin_font(19, 26.5, -20, bold);
}
.txt_BodyCopy2 {
    @include mixin_font(19, 26.5, -20, bold);
}
.txt_BodyCopy3 {
    @include mixin_font(15, 21, -10, regular);
}
.txt_BodyCopy4 {
    @include mixin_font(15, 19, -10, bold);
}
.txt_Footnote1 {
    @include mixin_font(15, 37, 150, bold);
}
.txt_ArticlesCredits {
    @include mixin_font(13, 19, -10, bold);
    font-style: italic;
}
.txt_Footnote2 {
    @include mixin_font(12, 15, 0, regular);
}
.txt_Quote {
    @include mixin_font(48, 60, -20, regular);
}
.txt_QuoteName {
    @include mixin_font(28, 36, 0, regular);
}
.txt_Subscribe {
    @include mixin_font(38, 52, -20, bold);
}
.txt_SectionLabel {
    @include mixin_font(15, 24, 150, bold);
    text-transform: uppercase;
}
.txt_SectionName {
    @include mixin_font(82, 62, -20, bold);
}
// Helper Classes
.ltx_Container {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    border: 1px dotted #999;
}
.ltx_ArrowLink {
    @apply mx-auto;
    @include mixin_font(15, 22, -20, bold);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: var(--c-grey-dark);
    cursor: pointer;
    white-space: nowrap;
    > i {
        display: block;
        width: 20px;
        height: 15px;
        background: url("/icon_arrowlink.svg") center no-repeat;
        padding: 5px;
        box-sizing: content-box;
        display: none;
    }
    &.ltx_LeftAlign {
        align-items: flex-start;
    }
    &:hover {
        > i {
            background-image: url("/icon_arrowlinkred.svg");
        }
    }
}
.ltx_ArrowLinkRed {
    @apply mx-auto;
    @include mixin_font(15, 24, 150, bold);
    display: inline-block;
    color: var(--c-b-red);
    cursor: pointer;
    background: url("/icon_arrowlinkred.svg") center right no-repeat;
    background-size: 20px 15px;
    padding-right: 30px;
    white-space: nowrap;
    /* temporary */
    padding-right: 0;
    background: none;
}
.ltx_ArticleBox {
    width: 290px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 0px;
    padding: 14px;
    margin-bottom: 34px;
    justify-content: center;
    cursor: pointer;
    .ltx_Image {
        background: transparent;
        border-radius: 20px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        aspect-ratio: 1;
        overflow: hidden;
    }
    .ltx_Title {
        @apply my-6 px-6 text-center mb-3;
        @include mixin_maxlines(2);
        @extend .txt_Header6;
    }
    &.ltx_Transparent {
        background-color: transparent;
    }
    .ltx_Label {
        @extend .ltx_Bar;
        color: var(--c-b-blue);
    }
    .ltx_ArrowLink {
        @apply mx-auto w-full;
    }
    &:hover {
        .ltx_Title {
            text-decoration: underline;
        }
        .ltx_ArrowLink i {
            background-image: url("/icon_arrowlinkred.svg");
        }
    }
    &.ltx_Short {
        .ltx_ArrowLink {
            font-size: 0;
            line-height: 0;
        }
    }
}
.ltx_Tag {
    @include mixin_font(13, 14, 0, bold);
    background: #efefef;
    color: black;
    margin-left: 18px;
    padding: 10px 15px 10px 15px;
    border-radius: 32px;
    cursor: pointer;
    &.ltx_Selected {
        background-color: #ff504e;
        color: white;
    }
    &:hover {
        background-color: var(--c-b-darkgrey);
        color: white;
    }
}
.ltx_CreatorCard {
    background: white;
    // border-radius: 34px;
    padding: 10px 20px 10px;
    margin-bottom: 40px;
    position: relative;
    display: block;
    width: 270px !important;
    cursor: pointer;
    flex-grow: 0;

    &.ltx_Evangelist {
        &::before {
            content: "";
            width: 17px;
            height: 17px;
            position: absolute;
            top: 1rem;
            left: 1rem;
            display: block;
            background: url(/blue_star.svg) center no-repeat;
        }
    }
    .ltx_Image {
        // background: #939598;
        width: 230px;
        height: 230px;
        // border-radius: 50% 50% 0px 0px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: 14px !important;
        margin-bottom: 14px !important;
        display: block;
    }
    .ltx_Label {
        @extend .ltx_Bar;
        color: var(--c-b-blue);
    }
    .ltx_Title {
        @extend .txt_Header5;
        @apply text-center;
    }
    .ltx_ArrowLink {
        @apply mt-2 w-full mx-auto;
    }
    &:hover {
        .ltx_Title {
            text-decoration: underline;
        }
        .ltx_ArrowLink i {
            background-image: url("/icon_arrowlinkred.svg");
        }
    }
}

.ltx_ShareButton_Holder {
    width: calc(100% - 200px);
    display: inline-flex;
    align-items: center;
    position: relative;
    &::before {
        content: "Share with";
        display: block;
        position: absolute;
        top: -20px;
        @include mixin_font(11, 13, -40, regular);
        color: #939598;
    }
}
.ltx_ShareButton {
    width: 31px;
    height: 31px;
    display: block;
    margin-right: 10px;
    background: var(--c-grey-dark);
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    float: left;
    @include mixin_animation(all, 0.1s);
    &.ltx_facebook {
        background-image: url("/share_icon_facebook.svg");
    }
    &.ltx_twitter {
        background-image: url("/share_icon_twitter.svg");
    }
    &.ltx_instagram {
        background-image: url("/share_icon_instagram.svg");
    }
    &.ltx_linkedin {
        background-image: url("/share_icon_linkedin.svg");
    }
    &.ltx_tiktok {
        background-image: url("/share_icon_tiktok.svg");
    }
    &.ltx_link {
        background-image: url("/share_icon_link.svg");
        background-size: 21px;
    }
    &:hover {
        background-color: var(--c-b-red);
    }
    &.ltx_text {
        width: auto;
        padding: 8px 14px;
        border-radius: 31px;
        color: white;
        font-size: 0.7rem;
    }
}
.ltx_ReactionButtons {
    // border: 1px solid red;
    float: right;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    .ltx_ReactionButton {
        // width: 31px;
        // height: 31px;
        display: inline-block;
        background: var(--c-grey-dark);
        border-radius: 20px;
        padding: 8px 12px 6px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        color: white;
        font-size: 0.7rem;
        width: 31px;
        text-align: center;
        @include mixin_animation(all, 0.1s);
        &.ltx_facebook {
            background-image: url("/share_icon_facebook.svg");
        }
        &.ltx_twitter {
            background-image: url("/share_icon_twitter.svg");
        }
        &:hover {
            background-color: var(--c-b-red);
        }
    }

    .ltx_Love,
    .ltx_More {
        background-image: url("/icon_love.svg");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 31px;
        @include mixin_font(11, 13, -40, regular);
        color: #939598;
        background-color: transparent !important;
        padding: 37px 5px 0px;
        border-radius: 0;
        &:hover {
            color: var(--c-grey-dark);
        }
    }
    .ltx_More {
        background-image: url("/icon_more.svg");
    }
}
.lazy {
    background-image: none !important;
    background-color: #f1f1fa;
}
.ltx_Bar {
    @extend .txt_SectionLabel;
    @apply mt-3 mb-2 text-center uppercase pt-1 pb-1 px-7;
    color: var(--c-b-blue);
}

.ltx_ArrowsContainer {
    // border: 1px solid blue;
    position: absolute;
    top: 50%;
    left: calc(50vw - 182px);
    width: calc(100% - 50vw + 182px);
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .ltx_ArrowIconRight {
        width: 50px;
        height: 50px;
        cursor: pointer;
        background: url("/icon_arrow_right.svg") center no-repeat;
        background-size: 32px;
        // border: 1px solid red;
        &:hover {
            background-image: url("/icon_arrow_right_red.svg");
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
    .ltx_ArrowIconLeft {
        @extend .ltx_ArrowIconRight;
        transform: scale(-1);
    }
}
