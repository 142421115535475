@import "globals";

$navAnimationSpeed: 0.5s;

body,
html {
    // background: var(--c-grad-green);
    background: var(--c-b-white);
    font-family: BrownStd;
}
%center_block {
    width: 100%;
    max-width: 1550px;
    margin: 0 auto;
}
main {
    padding-top: var(--s-full-nav-height);
    @include mixin_animation(all, $navAnimationSpeed);
}
main.ltx_Short {
    padding-top: var(--s-short-nav-height);
}

// Loading
body > div > div {
    &::before {
        background: white;
        // background-image: url("/loading.svg");
        // background-position: center;
        // background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        content: "loading...";
        position: fixed;
        top: -100vh;
        width: 100vw;
        height: 100vh;
        z-index: 10000;
        opacity: 0;
        @include mixin_animation(all, 0.3s);
        font-family: Vesterbro;
        font-weight: 500;
        font-size: 5rem;
        color: #fbe3e3;
    }
    &.ltx_Loading::before {
        opacity: 0.9;
        top: 0;
        // temporary
        display: none;
    }
}

.ltx-Navigation {
    // @extend %center_block;
    @include mixin_animation(height, $navAnimationSpeed);
    // transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    @apply bg-white pt-2;
    height: var(--s-full-nav-height);
    border: 1px solid transparent;
    position: relative;
    border-bottom: 1px solid #777;
    overflow: hidden;
    // > * {
    //     outline: 1px solid blue;
    // }

    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100vw;

    .ltx_mobile_toggle {
        display: none;
    }

    // LTX Logo
    .ltx_navigation__logo {
        // border: 1px solid red;
        cursor: pointer;
        width: 167px;
        height: 35px;
        position: absolute;
        left: calc(50% - 546px);
        top: 10px;
    }
    .ltx_navitaion__container {
        // border: 1px solid red;
        max-width: 1151px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;

        .ltx_navigation__rightarticle {
            // @include mixin_animation(all);
            width: 266px;
            height: 190px;
            position: relative;
            text-align: right;
            cursor: pointer;
            > img {
                // border: 1px solid red;

                float: left;
            }
            > span {
                @include mixin_font(24, 27, -20, bold);
                position: absolute;
                top: 30px;
                left: 175px;
                text-align: left;
                z-index: 1;
                width: 150px;
            }
            &:hover {
                > span {
                    text-decoration: underline;
                }
            }
        }

        .ltx_navigation__sitelogo {
            // @include mixin_animation(all);
            // transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
            width: 332px;
            height: 175px; //used to be 162
            // border: 1px solid red;
            width: calc(568px + 4rem);
            text-align: center;
            cursor: pointer;
            transform: scale(1.1) translateY(-5px) translateX(10px);
        }

        .ltx_navigation__leftarticle {
            // @include mixin_animation(all);
            width: 250px;
            text-align: center;
            cursor: pointer;
            > span {
                @include mixin_font(15, 19, 150, bold);
                text-transform: uppercase;
                color: var(--c-b-red);
                margin-bottom: 10px;
                margin-top: 10px;
                display: block;
            }
            > div {
                @include mixin_font(30, 33, -20, bold);
                // font-family: var(--ff-);
                color: var(--c-b-darkgrey);
                &:hover {
                    text-decoration: underline;
                }
            }
            &:hover {
                > div {
                    text-decoration: underline;
                }
            }
        }
    }

    .ltx-navigation__sectionsContainer {
        border: 0;
        position: relative;
        max-width: none;
        @include mixin_animation(all);
        transition-timing-function: cubic-bezier(0.08, 0.69, 0.13, 0.99);
        > div:first-of-type,
        > div:last-of-type {
            width: 280px;
        }
        .ltx-navigation__sections {
            // border: 1px solid var(--c-grey-dark);
            a {
                border-right: 1px solid var(--c-grey-dark);
                white-space: nowrap;
                text-transform: uppercase;
                &:last-of-type {
                    border: 0;
                }
                &:hover {
                    color: var(--c-b-red);
                    text-decoration: underline;
                }
                &.ltx_Active {
                    color: var(--c-b-red);
                }
            }
            div.ltx_search {
                width: 28px;
                height: 17px;
                margin-left: 0px;
                cursor: pointer;
                margin-top: -1px;
                background: url("/nav_icon_search.svg") center no-repeat;
                &:hover {
                    background-image: url("/nav_icon_search_hover.svg");
                }
            }
        }
        .ltx-navigation__sectionsExtras {
            @include mixin_animation(all);
            a,
            div {
                width: 28px;
                height: 17px;
                margin-left: 5px;
                cursor: pointer;
                margin-top: -4px;
                &.ltx_facebook {
                    background: url("/nav_icon_facebook.svg") center no-repeat;
                }
                &.ltx_twitter {
                    background: url("/nav_icon_twitter.svg") center no-repeat;
                }
                &.ltx_instagram {
                    background: url("/nav_icon_instagram.svg") center no-repeat;
                }
                &.ltx_tiktok {
                    background: url("/nav_icon_tiktok.svg") center no-repeat;
                }
                &.ltx_aboutus {
                    @extend .txt_NavigationSubscribe;
                    width: auto;
                    color: var(--c-b-darkgrey);
                    margin-top: -1px;
                    position: absolute;
                    right: 40px;
                    &:hover {
                        color: var(--c-b-red);
                        text-decoration: underline;
                    }
                    &.ltx_selected {
                        color: var(--c-b-red);
                    }
                }
            }
            &:nth-of-type(3) {
                // border: 1px solid red;
                padding-left: 30px;
            }
        }
    }

    &.ltx_Short {
        // border: 1px solid red;
        height: 118px;
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 100%;

        .ltx_navigation__leftarticle,
        .ltx_navigation__rightarticle {
            opacity: 0;
            height: 0px;
            overflow: hidden;
            margin-top: 0px;
            //transform: translateY(-240px) scale(0);
        }
        .ltx_navigation__logo,
        .ltx-navigation__sectionsExtras:nth-of-type(1) {
            opacity: 0;
            transform: translateY(-200px);
        }
        .ltx_navigation__logo {
            top: -35px;
        }
        .ltx_navigation__sitelogo {
            position: absolute;
            top: 15px;
            margin-left: -30px;
            width: 165px;
            height: 74px;
            transform: scale(1);
            z-index: 1;
        }
        .ltx-navigation__sectionsContainer {
            margin-top: -40px;
            .ltx-navigation__sections {
                a {
                    padding-left: 0.6rem !important;
                    padding-right: 0.6rem !important;
                }
            }
        }
    }
}

.ltx-Subscribe {
    background: url("/subscribe_background.png") white center 170px no-repeat;
    background-image: none;
    z-index: 1;
    position: relative;
    form {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    // label {
    //     margin-top: 40px;
    //     min-width: 470px;
    //     text-align: left;
    //     @include mixin_font(13, 24, 150, bold);
    //     text-transform: uppercase;
    //     padding: 0 30px;
    // }
    input {
        @include mixin_font(20, 43, 0, regular);
        border: 1px solid var(--c-b-darkgrey);
        padding: 7px 160px 5px 40px;
        border-radius: 30px;
        min-width: 530px;
        margin-top: 40px;
        &::placeholder {
            color: #c7c8cb;
        }
    }
    button {
        position: absolute;
        border: 1px solid var(--c-b-darkgrey);
        top: 40px;
        padding: 7px 40px 5px;
        border-radius: 30px;
        @include mixin_font(20, 43, 0, regular);
        background: var(--c-b-darkgrey);
        color: white;
        right: 0;
    }
    // display: none !important; // temporary
}
.ltx-Footer {
    @apply text-white py-8 pb-8;
    background: #0c0e19;
    display: inline-flexbox;
    .ltx_Container {
        border: none;
        @apply flex flex-row justify-start;
        > div:first-of-type {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @include mixin_font(15, 37, 150, bold);
            > div {
                overflow: visible !important;
                margin-bottom: 2rem !important;
            }
        }

        .ltx_footer__logo {
            margin-left: -3px !important;
            margin-bottom: 5px;
            width: 145px !important;
            height: 30px !important;
        }
        a.ltx_footer__section {
            &:hover {
                text-decoration: underline;
            }
        }

        .ltx_SocialHolder {
            display: flex;
            flex-direction: row;
            > a {
                // margin-top: 0.5rem;
                margin-right: 0.5rem;
                width: 36px;
                height: 36px;
                display: inline-block;
            }
        }
        > div:first-of-type {
            @include mixin_font(15, 37, 150, bold);
        }

        > div:last-of-type {
            display: flex;
            flex-direction: row;
            ul {
                margin-left: 100px;
                li {
                    @include mixin_font(16, 37, -20, bold);
                    span {
                        color: white;
                    }
                    a {
                        padding: 1rem 0;
                        margin: 0;
                        @include mixin_font(16, 37, -20, bold);
                        &:hover {
                            text-decoration: underline;
                            color: var(--c-b-red);
                        }
                    }
                }
            }
        }
    }
}

.ltx-ZoneThreeColumns {
    // border: 1px solid green;
    @apply flex flex-row py-7;
    justify-content: space-between;
    gap: 2rem;
    min-height: 1120px;
    // Left side
    > div:first-of-type {
        color: var(--c-b-darkgrey);
        flex-grow: 1;
        background: url("/threezones_left_area.svg") right top no-repeat;
        padding-top: 50px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-size: auto 100%;

        .ltx_zonethreecols_LeftArticle {
            // border: 1px solid red;
            text-align: center;
            width: 230px;
            margin-bottom: 25px;
            cursor: pointer;

            .ltx_Image {
                width: 224px;
                height: 138px;
                border-radius: 18px;
                background: white;
                margin-bottom: 18px;
                // border: 1px solid #ddd;
                background-position: center;
                background-size: cover;
                overflow: hidden;
            }
            > .ltx_Label {
                @extend .ltx_Bar;
                color: var(--c-b-blue);
                margin-bottom: -4px;
                margin-top: 8px;
            }
            > .ltx_Title {
                @extend .txt_Header6;
                @include mixin_maxlines(3);
                @apply mx-auto w-full text-center;
                font-weight: var(--fw-regular);
            }
            > .ltx_Announce {
                @extend .txt_Header5;
                @include mixin_maxlines(2);
            }
            &:hover {
                > .ltx_Title,
                > .ltx_Announce {
                    text-decoration: underline;
                }
            }

            &.ltx_FlushLeft {
                > .ltx_Label {
                    text-align: left;
                    padding: 0 15px;
                    padding-bottom: 6px;
                }
                > .ltx_Title {
                    text-align: left;
                    padding: 0 15px;
                }
                > .ltx_Announce {
                    @apply text-left;
                }
            }
        }
    }
    // Right side
    > div:last-of-type {
        color: var(--c-b-darkgrey);
        flex-grow: 1;
        background: url("/threezones_right_area.svg") left top 12px no-repeat;
        padding-top: 100px;
        padding-left: 30px;

        .ltx_zonethreecols_RightArticle {
            // border: 1px solid red;
            text-align: center;
            width: 220px;
            margin-bottom: 10px;
            cursor: pointer;
            .ltx_Image {
                width: 148px;
                height: 148px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin: 0 auto;
            }
            > .ltx_Label {
                @extend .ltx_Bar;
                line-height: 20px;
                color: var(--c-b-blue);
                margin-bottom: 0px;
                margin-top: 2px;
            }
            > .ltx_Title {
                @extend .txt_Header5;
                @apply mx-auto w-2/3;
            }
            > .ltx_Announce {
                @extend .txt_BodyCopy4;
            }
            &:hover {
                > .ltx_Title,
                > .ltx_Announce {
                    text-decoration: underline;
                }
                > .ltx_ArrowLink i {
                    background-image: url("/icon_arrowlinkred.svg");
                }
            }
            &.ltx_FlushLeft {
                text-align: left;
                > .ltx_Label {
                    text-align: left;
                    padding-left: 0;
                }
                > .ltx_Title {
                    text-align: left;
                    margin: 0;
                }
                > .ltx_Announce {
                    text-align: left;
                }
            }
        }
    }
    // Center area
    > div:nth-of-type(2) {
        cursor: pointer;
        color: var(--c-b-black);
        width: 568px;
        flex-grow: 0;
        flex-shrink: 0;
        background: white;
        // border-radius: 70px;
        border-radius: 0px;
        padding: 20px;
        text-align: center;
        position: relative;
        cursor: pointer;

        .ltx_zonethreecols_Image {
            background: white;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            aspect-ratio: 1;
            border-radius: 46px;
            overflow: hidden;
        }
        .ltx_zonethreecols_Label {
            @apply mx-auto;
            @include mixin_font(15, 24, 150, bold);
            display: inline-block;
            color: var(--c-b-darkgrey);
            text-transform: uppercase;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            position: relative;
            transform: translateY(-30px);
        }
        .txt_Header1 {
            @include mixin_font(46, 48, -10, regular);
        }
        .txt_BodyCopy1 {
            color: var(--c-b-darkgrey);
            @include mixin_maxlines(8);
            @include mixin_font(28, 34, -10, regular);
            @apply my-8;
            width: 50%;
        }
        .ltx_ArrowLink {
            color: var(--c-grey-normal);
        }
        &:hover {
            .txt_Header1 {
                text-decoration: underline;
            }
            .ltx_ArrowLink > i {
                background-image: url("/icon_arrowlinkred.svg");
            }
            .ltx_ArrowLink {
                color: var(--c-b-darkgrey);
            }
        }
    }

    // Zonesection Label
    .ltx_zonethreecols_Label {
        @extend .ltx_Bar;
        @apply mb-4;
        @include mixin_font(29, 32, -10, bold);
        text-align: center;
        // width: 230px;
    }
    > div:not(:nth-of-type(2)) .ltx_zonethreecols_Label {
        color: var(--c-b-red) !important;
        max-width: 220px;
        text-align: center;
        padding: 0;
    }
}

.ltx-BigArticleZone {
    // border: 1px solid green;
    @apply flex flex-row;
    padding-top: 88px;
    padding-bottom: 112px;
    display: inline-flexbox;
    > .ltx_Container {
        display: flex;
        border: 0;
        cursor: pointer;
        > div {
            // border: 1px dotted black;
            width: 50%;
            height: 600px;
            @apply py-5 px-8;
        }
        > div:last-of-type {
            @apply px-20 pt-16;

            .ltx_Label {
                @apply mx-auto px-8;
                @include mixin_font(15, 24, 150, bold);
                display: inline-block;
                color: var(--c-b-darkgrey);
                text-transform: uppercase;
                padding-top: 0.3rem;
                padding-bottom: 0.3rem;
                background: var(--c-b-blue);
                margin-bottom: 30px;
            }
            .ltx_Title {
                @extend .txt_Header1;
                margin-bottom: 30px;
            }
            .ltx_Announce {
                @extend .txt_BodyCopy1;
                color: #414041;
                margin-bottom: 30px;
                @include mixin_maxlines(6);
                max-height: 210px;
            }
        }
        .ltx_Image {
            background-size: cover;
            background-position: center;
        }
    }
    &:hover {
        .ltx_Container {
            .ltx_Title {
                text-decoration: underline;
            }
        }
    }
}

.ltx-QuoteZone {
    @apply py-8 bg-white;
    // border: 1px solid red;
    .ltx_Container {
        border: 0;
        @apply flex flex-col;

        .ltx_Label {
            @extend .txt_HomePageTitle;
            @apply mb-6 text-center;
            // color: var(--c-b-blue);
        }
        .ltx_Image {
            @apply mb-8;
            background: #707070;
            height: 582px;
            display: block;
        }

        .ltx_Quote {
            @extend .txt_Quote;
            @apply mx-8 mt-8;
            color: #2e323a;
            // &::before {
            //     content: "”";
            //     margin-left: -26px;
            // }
        }
        .ltx_Name {
            @extend .txt_QuoteName;
            // @include mixin_font(24, 36, 100, bold);
            margin-top: 13px;
            // text-transform: uppercase;
            /*
            display: inline;
            margin-left: 20px;
            */
            display: block;
            // font-size: 28px;
        }
    }
    &.ltx_NoImage {
        @apply pt-0;
    }
}

.ltx-GridZone {
    @apply pt-8;
    // border: 1px solid red;
    background: var(--c-blue-light);
    .ltx_Container {
        @apply flex flex-row;
        border: 0;
        justify-content: space-evenly;
        > div:not(.txt_HomePageTitle) {
            width: 290px;
            text-align: center;
            // border: 1px solid black;
            padding-bottom: 80px;
        }
        .txt_HomePageTitle {
            width: 100%;
        }
    }
    &.ltx_Section {
        .ltx_Container:nth-of-type(2) {
            // border: 1px solid red;
            > div:first-of-type,
            > div:last-of-type {
                // border: 1px solid blue;
                padding-top: 80px;
            }
        }
        .ltx_Container {
            > div:not(.txt_HomePageTitle) {
                padding-bottom: 0;
            }
        }
        .ltx_Container {
            .ltx_ArticleBox {
                .ltx_Title {
                    @include mixin_maxlines(4);
                }
            }
        }
    }
}

.ltx-ShortGridZone {
    @apply pt-12 pb-12;
    // border: 1px solid red;
    background: var(--c-b-blue);
    background-image: url("/shortgridtrapeze_pink.svg");
    background-position: center bottom -1px;
    background-repeat: no-repeat;
    background-size: min(1300px, 100vw);
    .ltx_Container {
        @apply flex flex-row justify-evenly px-14;
        border: 0;
        gap: 50px;
        > div {
            width: 100%;
            text-align: center;
            // border: 1px solid black;
            padding-bottom: 0px;
        }
        .ltx_ArticleBox {
            // width: 100%;
            min-height: 434px;
            .ltx_Image {
                background-color: transparent;
            }
        }
        .ltx_Label {
            @extend .ltx_Bar;
            @apply mt-4;
            color: var(--c-b-blue);
        }
        .ltx_Title {
            @include mixin_maxlines(3);
            margin-top: 5px;
            padding: 0 20px;
            min-height: 72px;
        }
        &:nth-of-type(2) {
            @apply pb-8;
        }
    }
}

.ltx-WideArticleZone {
    // border: 1px solid green;
    @apply flex flex-col py-8 bg-white;
    display: inline-flexbox;
    > .ltx_Container {
        @apply flex flex-col;
        border: 0;

        > .ltx_Image {
            width: 100%;
        }

        > div:last-of-type {
            @apply mx-auto text-center;
            width: 100%;
            max-width: 550px;
        }

        // .ltx_Label {
        //     @apply mx-auto px-8;
        //     @include mixin_font(15, 24, 150, bold);
        //     display: inline-block;
        //     color: var(--c-b-darkgrey);
        //     text-transform: uppercase;
        //     padding-top: 0.3rem;
        //     padding-bottom: 0.3rem;
        //     background: var(--c-b-blue);
        //     margin-bottom: 30px;
        // }
        .ltx_Title {
            @apply text-center mt-8;
            @extend .txt_Header1;
            margin-bottom: 30px;
        }
        .ltx_Announce {
            @extend .txt_BodyCopy1;
            color: #414041;
            margin-bottom: 30px;
        }
    }
}

.ltx_SectionTitle {
    @extend .txt_SectionName;
    @apply text-center py-5;
    background: var(--c-grad-green);
    color: var(--c-b-red);
    &.ltx_LibraryTitle {
        background: var(--c-grad-greyred);
    }
    &.ltx_CreatorsTitle {
        background: var(--c-grad-red);
    }
    &.ltx_Yellow_Blue {
        background: var(--c-grad-green);
    }
    &.ltx_Blue_Pink {
        background: var(--c-grad-blue);
    }
    &.ltx_Pink_Yellow {
        background: var(--c-grad-pink);
    }
    &.ltx_Red_Pink {
        background: var(--c-grad-red);
    }
    &.ltx_Blue_Red {
        background: var(--c-grad-greyred);
    }
    &.ltx_howto {
        background: #fff6fa;
    }
    &.ltx_inspiration {
        background: #ffeef5;
    }
    &.ltx_wellness {
        background: #eafcff;
    }
    &.ltx_trends {
        background: #fffdef;
    }
    &.ltx_LibraryTitle {
        background: #ffbdba;
    }
    &.ltx_community {
        background: #c9f2fb;
    }
    &.ltx_CreatorsTitle {
        background: #4cc7e1;
    }
    &.ltx_AboutusTitle {
        background: #a5e3f0;
    }
}
.ltx-SectionDiscordChannel {
    @apply flex items-center justify-center text-white text-4xl;
    background: #939598;
    height: 558px;
}
.ltx-SectionBottomGrid {
    background: var(--c-blue-xlight);
    @apply py-6 bg-white;
    // border: 1px solid red;
    .ltx_Container:first-of-type {
        border: 0;
        .ltx_Label {
            @apply text-center mx-auto uppercase mb-4;
            @include mixin_font(29, 62, -20, bold);
            color: var(--c-b-red);
            position: relative;
            &::before {
                content: "";
                width: 17px;
                height: 17px;
                position: absolute;
                top: 1.32rem;
                left: -2rem;
                display: block;
                background: url(/red_star.svg) center no-repeat;
            }
            &::after {
                content: "";
                width: 17px;
                height: 17px;
                position: absolute;
                top: 1.32rem;
                right: -2rem;
                display: block;
                background: url(/red_star.svg) center no-repeat;
            }
        }
    }
    .ltx_Container:not(:first-of-type) {
        @apply flex flex-row content-evenly px-36;
        border: 0;
        gap: 30px 50px;
        justify-content: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        > div {
            // border: 1px solid grey;
            width: 100%;
        }
    }
    .ltx_Container:last-of-type {
        grid-template-columns: 1fr;
    }
    &.ltx_Creators {
        .ltx_Container:last-of-type {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.ltx-ArticleBody {
    font-family: BrownStd;
    .ltx_Container {
        margin: 0;
        border: 0;
        max-width: unset;
        margin-left: calc((100% - 1200px) / 2);
        .ltx_LeftColumn {
            width: 634px;
            margin-right: 130px;
            padding-left: 95px;
            // border: 1px solid red;
            // padding-top: 96px;
            padding-top: 72px;
            h3 {
                @extend .txt_BodyCopy2;
            }
            hr {
                border-top: 1px solid #939598;
                @apply my-7;
            }
            .ltx_BodyContainer {
                // border: 1px solid red;
                p {
                    @include mixin_font(15, 21, -10, regular);
                    margin-bottom: 12px;
                }
                h2 {
                    @include mixin_font(26, 32, -10, bold);
                }
                h3 {
                    @include mixin_font(19, 26.5, -10, bold);
                    line-height: 22px;
                    margin-bottom: 12px;
                    margin-top: 20px;
                }
                h4 {
                    @include mixin_font(26, 32, -10, bold);
                    display: inline-flex;
                    align-items: flex-start;
                    a {
                        font-size: 16px;
                        margin-left: 10px;
                        display: inline-block;
                        opacity: 0.3;
                        background-image: url("/share_icon_link_primary.svg");
                        background-size: 18px;
                        background-position: center;
                        background-repeat: no-repeat;
                        width: 23px;
                        height: 18px;
                        margin-top: 4px;
                        position: relative;
                        > div {
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            background: white;
                            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                            top: -5px;
                            left: -2px;
                            background-image: url("/share_icon_link_primary.svg");
                            background-size: 18px;
                            background-position: 5px 7px;
                            background-repeat: no-repeat;
                            padding-left: 30px;
                            font-size: 14px;
                            padding-right: 5px;
                            white-space: nowrap;

                            > div {
                                color: var(--c-b-darkgrey);
                                font-size: 11px;
                                font-weight: 300;

                                padding: 0;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                            > div.ltx_ShareButton_Holder {
                                width: 100%;
                                flex-direction: row;
                                display: flex;
                                padding-bottom: 10px;
                                &::before {
                                    display: none;
                                }
                            }
                        }
                        &:hover {
                            opacity: 1;
                            > div {
                                display: inline-flex;
                            }
                        }
                    }
                }
                ul {
                    list-style: disc;
                    li {
                        @include mixin_font(15, 21, -10, regular);
                        list-style-position: outside;
                        margin-left: 13px;
                        &::marker {
                            color: #000;
                        }
                    }
                }
                ol {
                    counter-reset: my-awesome-counter;
                    li {
                        @include mixin_font(15, 21, -10, regular);
                        margin-bottom: 20px;
                        list-style-type: none;
                        counter-increment: my-awesome-counter;
                        &:first-line,
                        strong {
                            @include mixin_font(28, 32, -10, bold);
                        }
                        &::marker {
                            display: none;
                        }
                        &::before {
                            content: counter(my-awesome-counter);
                            @include mixin_font(19, 26.5, -10, bold);
                            color: white;
                            width: 22px;
                            height: 44px;
                            background: url("/article_style1_numbers.svg")
                                center 2px no-repeat;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 18px;
                        }
                    }
                }
                blockquote {
                    @extend .txt_Quote;
                    margin: 0;
                    padding: 0;
                    color: #2e323a;
                    // border: 1px solid red;
                    position: relative;
                    margin-bottom: 35px;
                    &::before {
                        content: "”";
                        margin-left: -16px;
                        position: absolute;
                    }
                    &::after {
                        content: "”";
                        display: inline;
                    }
                    @include mixin_font(24, 34, -20, bold);

                    &.tiktok-embed::before,
                    &.tiktok-embed::after,
                    &.instagram-media::before,
                    &.instagram-media::after {
                        display: none;
                    }
                }

                blockquote > p {
                    @include mixin_font(24, 34, -20, bold);
                    margin: 0;
                    padding: 0;
                    display: inline;
                }
                a {
                    color: var(--c-b-red) !important;

                    span {
                        color: var(--c-b-red) !important;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
                figure {
                    figcaption {
                        display: none;
                    }
                    &.image-style-align-center {
                        display: flex;
                        justify-content: center;
                    }
                    &.image-style-align-right {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }

            .ltx_Tags {
                margin-top: 55px;
                margin-bottom: 55px;
                span,
                a {
                    @extend .txt_ArticlesTag;
                    @apply py-2 px-6 mr-4 rounded-full;
                    color: var(--c-b-black);
                    display: inline-block;
                    background: var(--c-grey-light);
                    cursor: pointer;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    //margin-left: 10px;
                    &:hover {
                        background: var(--c-b-red);
                        color: white;
                    }
                }
            }
        }
        .ltx_RightColumn {
            flex-grow: 1;
            padding-top: 30px;
            // border: 1px solid red;
            background-size: cover;
            // aspect-ratio: 1/1.75;
            background: url("/article_right_trapeze.svg") no-repeat left 80px;
            background-size: auto 910px;
            .ltx_Holder {
                @apply flex flex-col items-center;
                width: 415px;
                // border: 1px solid grey;
                > .ltx_Title {
                    @include mixin_font(25, 28, -10, bold);
                    @apply text-center mb-4;
                    color: var(--c-b-red);
                }
                .ltx_Article {
                    width: 224px;
                    @apply flex flex-col items-center;
                    margin-bottom: 45px;
                    cursor: pointer;
                    .ltx_Image {
                        width: 224px;
                        height: 138px;
                        border-radius: 18px;
                        // background: grey;
                        background-size: cover;
                        background-position: center;
                        margin-bottom: 3px;
                    }
                    .ltx_Label {
                        @extend .ltx_Bar;
                        color: var(--c-b-blue);
                        margin-bottom: 0;
                    }
                    .ltx_Title {
                        @include mixin_font(20, 24, -10, regular);
                        @apply text-center;
                    }
                    .ltx_Author {
                        @include mixin_font(11, 14, 150, bold);
                        @apply uppercase text-center mt-3 mb-2;
                    }
                    .ltx_ArrowLink {
                        @apply mx-auto;
                    }
                    &:hover {
                        .ltx_Title {
                            text-decoration: underline;
                        }
                        .ltx_ArrowLink i {
                            background-image: url("/icon_arrowlinkred.svg");
                        }
                    }
                }
            }
        }
    }
}
.ltx-ArticleHeader {
    // border: 1px solid red;
    height: 530px;
    background-repeat: no-repeat;
    // background-position: calc(50% + 420px);
    background-position: calc(50vw - 600px + 20vw);
    .ltx_Container {
        height: 530px;
        border: 0;
    }
    .ltx_LeftColumn {
        @apply pt-16 pb-6;
        width: 330px;
        .ltx_Label {
            @extend .txt_SectionLabel;
            @apply uppercase pt-1 pb-1 px-7;
            display: inline-block;
            background: #fff280;
        }
        h1 {
            @extend .txt_Header2;
            // @include mixin_maxlines(3);
            @apply my-4 mb-2;
            padding-right: 20px;
        }
        h3 {
            @extend .txt_ArticlesSubhead1;
            @include mixin_maxlines(5);
            @apply w-4/5 mb-4;
        }
        .ltx_Author {
            @extend .txt_ArticlesAuthor;
        }
        .ltx_Date {
            @extend .txt_ArticlesDate;
            @apply mb-4;
        }
        .ltx_Tags {
            span,
            a {
                @extend .txt_ArticlesTag;
                @apply py-2 px-6 mr-4 rounded-full;
                color: var(--c-b-black);
                display: inline-block;
                background: var(--c-grey-light);
                cursor: pointer;
                margin-bottom: 10px;
                margin-left: 10px;
                &:hover {
                    background: var(--c-b-red);
                    color: white;
                }
            }
        }
    }
    // .ltx_RightColumn {
    //     flex-grow: 1;
    // }
}
.ltx-ArticleFooter {
    margin-bottom: 48px; // temporary
    .ltx_Container {
        padding-left: 95px;
        padding-right: 95px;
        border: 0;
        .ltx_Holder {
            margin-top: 40px;
            width: 100%;
            // height: 300px;
            // background: var(--c-grad-blue);
            background: var(--c-grad-bluelight);
            border-radius: 78px 0px 78px 0px;

            .ltx_Title {
                @include mixin_font(25, 28, -10, bold);
                @apply text-center my-4;
                color: var(--c-b-red);
            }
            .ltx_ArticlesBox {
                @apply flex flex-row mx-8;
                gap: 30px;
                // border: 1px solid red;
                .ltx_Article {
                    width: 100%;
                    // border: 1px solid blue;
                    @apply flex flex-row;
                    cursor: pointer;
                    .ltx_Image {
                        width: 143px;
                        height: 143px;
                        flex-shrink: 0;
                        // background: grey;
                        background-size: contain;
                        background-position: center;
                        // border-radius: 72px 72px 0 0;
                        // box-shadow: -5px 5px 0px var(--c-yellow-light);
                    }
                    .ltx_Label {
                        @include mixin_font(15, 24, 150, bold);
                        @apply uppercase;
                        // color: var(--c-b-blue);
                        color: var(--c-b-dark);
                        max-width: 140px;
                    }
                    .ltx_Title {
                        @extend .txt_Header5;
                        @apply text-left mt-0 mb-2;
                        color: var(--c-b-darkgrey);
                    }
                    .ltx_ArrowLink i {
                        background-image: url("/icon_arrowlinkdark.svg");
                    }
                    > div:nth-of-type(2) {
                        padding-left: 0.75rem !important;
                        display: flex;
                        align-items: center;
                    }

                    &:hover {
                        .ltx_Title,
                        .ltx_Label {
                            text-decoration: underline;
                        }
                        .ltx_ArrowLink i {
                            background-image: url("/icon_arrowlinkred.svg");
                        }
                    }
                }
            }
            .ltx_ArrowLinkRed {
                @apply uppercase mt-5 mb-4;
            }
        }
    }
}

.ltx-CreatorsHeader {
    // border: 1px solid red;
    height: 687px;
    // background-image: url("/creators_top_background.svg");
    // background-repeat: no-repeat;
    // background-position: calc(50% + 420px);
    .ltx_Container {
        height: 100%;
        display: block;
        position: relative;
        @apply pt-16 pb-6;
        border: 0;
        .ltx_Label {
            @extend .ltx_Bar;
            display: inline-block;
            color: var(--c-b-darkgrey);
            background: #fff280;
            display: none;
        }
        .ltx_Title {
            background: url("/creators_top_title.jpg") center no-repeat;
            display: block;
            // position: absolute;
            // top: 0;
            // left: 96px;
            // width: calc(100% - 96px);
            height: 100%;
            width: 100%;
            background-size: contain;
        }
    }
    .ltx_LeftColumn {
        width: 330px;
        background-color: var(--c-b-blue);
        h1 {
            @extend .txt_Header2;
            @apply my-4 mb-2;
        }
        h3 {
            @extend .txt_ArticlesSubhead1;
            @apply w-4/5 mb-4;
        }
        .ltx_Author {
            @extend .txt_ArticlesAuthor;
        }
        .ltx_Date {
            @extend .txt_ArticlesDate;
            @apply mb-4;
        }
        .ltx_Tags {
            span,
            a {
                @extend .txt_ArticlesTag;
                @apply py-2 px-6 mr-4 rounded-full;
                color: var(--c-b-black);
                display: inline-block;
                background: var(--c-grey-light);
                cursor: pointer;
                margin-bottom: 10px;
                margin-left: 10px;
                &:hover {
                    background: var(--c-b-red);
                    color: white;
                }
            }
        }
    }
    // .ltx_RightColumn {
    //     flex-grow: 1;
    // }
}
.ltx-FilterBar {
    @apply flex flex-row items-center;
    // border: 1px solid red;
    background: #a5e3f0;
    min-height: 138px;
    align-items: flex-start;
    padding: 20px 0;

    .ltx_Container {
        border: 0;
        align-items: flex-start;
    }
    .ltx_Toggle_Filters {
        @include mixin_font(13, 14, 150, bold);
        text-transform: uppercase;
        // background: black;
        // color: white;
        // background-image: url("/icon_filter.svg");
        // background-size: 20px 14px;
        // background-repeat: no-repeat;
        // background-position: 15px 9px;
        padding: 10px 5px 10px 40px;
        height: 32px;
        border-radius: 32px;
        position: relative;
        margin-right: 30px;
        min-width: 120px;
        .ltx_Tag {
            white-space: nowrap;
            margin: 0px 0;
            text-transform: none;
            background: none;
            text-decoration: underline;
            padding: 10px 0;
            &:hover {
                color: var(--c-b-red);
            }
        }
        &::after {
            content: "";
            background: #707070;
            width: 1px;
            height: 50px;
            position: absolute;
            display: block;
            top: -9px;
            right: -20px;
        }
    }
    .ltx_FilterHolder {
        @apply flex flex-row;
        // border: 1px solid red;
        flex-grow: 1;
        margin-right: 20px;
        max-width: 500px;
        flex-wrap: wrap;
        > .ltx_Tag {
            margin-bottom: 10px;
            margin-left: 10px;
        }
    }
    // .ltx_Tag {
    //     @include mixin_font(13, 14, 0, bold);
    //     background: #efefef;
    //     color: black;
    //     margin-left: 18px;
    //     padding: 10px 15px 10px 15px;
    //     border-radius: 32px;
    //     cursor: pointer;
    //     &:hover {
    //         background-color: #ff91c1;
    //     }
    // }
    input[type="text"] {
        background: url("/icon_spyglass.svg");
        background-size: 17px;
        background-repeat: no-repeat;
        background-position: right 10px center;
        border: 1px solid #000;
        border-radius: 32px;
        padding-right: 30px;
        padding-left: 15px;
        display: inline-block;
        float: right;
        height: 32px;
    }

    &.ltx_Hidetags {
        min-height: unset;
        .ltx_FilterHolder {
            display: none;
        }
        .ltx_Toggle_Filters {
            margin-right: 45px;
            text-align: right;
        }
        .ltx_Container {
            justify-content: center;
        }
    }
}
.ltx-CreatorsBody {
    background: #c9eef6;
    .ltx_Container {
        @apply flex flex-col;
    }

    h2 {
        color: var(--c-b-red);
        @apply mx-auto my-8 uppercase;
        @include mixin_font(29, 32, -10, bold);
    }
}

.ltx-LibraryHeader {
    // min-height: 687px;
    // background-image: url("/creators_top_background.svg");
    background-repeat: no-repeat;
    background-position: calc(50% + 420px);
    .ltx_Container {
        height: 100%;
        border: 0;
        position: relative;
        justify-content: center;

        .ltx_Image {
            margin: 3rem 0 0 0 !important;
            border-bottom: 1px solid #707070;
            margin-bottom: 3rem !important;
            padding-bottom: 2rem;
        }
    }
    .ltx_LibraryHeader_text {
        @extend .txt_BodyCopy1;
        margin-top: 0px;
        margin-bottom: 30px;
    }
}
.ltx-LibraryBody {
    @apply py-12;
    background: #fff280;
    .ltx_Container {
        border: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0px;
        row-gap: 30px;
        max-width: 1110px;
        a {
            .ltx_ArticleBox {
                margin: 0 auto;
                min-height: 433px;
                .ltx_Title {
                    @include mixin_maxlines(3);
                    @apply mt-3 mb-0;
                }
            }
        }
        .ltx_Category {
            @apply flex flex-col mx-auto;

            width: 290px;
            height: 390px;
            background: white;
            padding: 10px;
            .ltx_Image {
                background: #939598;
                border-radius: 20px;
                width: 100%;
                height: 244px;
            }
            .ltx_Title {
                @apply text-center mt-12;
                color: var(--c-b-red);
                @include mixin_font(29, 32, -10, bold);
            }
            .ltx_ArrowLink {
                @apply flex-grow;
            }
        }
    }
}

.ltx-CreatorHeader {
    // border: 1px solid red;
    height: 530px;
    background-repeat: no-repeat;
    // background-position: calc(50% + 420px);
    background-position: calc(50vw - 600px + 20vw);
    position: relative;
    .ltx_Container {
        height: 530px;
        border: 0;
    }
    .ltx_LeftColumn {
        @apply pt-16 pb-6;
        width: 330px;
        .ltx_Label {
            @extend .ltx_Bar;
            display: inline-block;
            color: var(--c-b-darkgrey);
            background: #fff280;
        }
        h1 {
            @extend .txt_Header2;
            // @include mixin_maxlines(3);
            @apply my-4 mb-2;
            padding-right: 20px;
        }
        h3 {
            @extend .txt_ArticlesSubhead1;
            @include mixin_maxlines(9);
            @apply w-4/5 mb-4;
        }
        .ltx_Author {
            @extend .txt_ArticlesAuthor;
        }
        .ltx_Date {
            @extend .txt_ArticlesDate;
            @apply mb-4;
        }
        .ltx_Tags {
            span,
            a {
                @extend .txt_ArticlesTag;
                @apply py-2 px-6 mr-4 rounded-full;
                color: var(--c-b-black);
                display: inline-block;
                background: var(--c-grey-light);
                cursor: pointer;
                margin-bottom: 10px;
                margin-left: 10px;
                &:hover {
                    background: var(--c-b-red);
                    color: white;
                }
            }
        }
    }
    // .ltx_RightColumn {
    //     flex-grow: 1;
    // }
    .ltx_DotsContainer {
        $size: 10px;
        // border: 1px solid red;
        position: absolute;
        left: calc(100% - (100% - (50% - 165px)) / 2);
        bottom: 0px;
        transform: translateX(-50%);
        span {
            @include mixin_animation(all, 0.5s);
            display: inline-block;
            width: $size;
            height: $size;
            background: var(--c-b-darkgrey);
            border: 1.5px solid white;
            margin-right: $size;
            border-radius: $size;
            opacity: 0.4;
            &.ltx_Active {
                opacity: 0.7;
            }
        }
    }
}
.ltx-CreatorBody {
    font-family: BrownStd;
    .ltx_Container {
        margin: 0;
        border: 0;
        max-width: unset;
        margin-left: calc((100% - 1200px) / 2);
        .ltx_LeftColumn {
            width: 634px;
            margin-right: 130px;
            padding-left: 95px;
            //padding-top: 96px;
            padding-top: 72px;

            h3 {
                @extend .txt_BodyCopy2;
            }
            hr {
                border-top: 1px solid #939598;
                @apply my-7;
            }
            .ltx_BodyContainer {
                // border: 1px solid red;

                p {
                    @include mixin_font(15, 21, -10, regular);
                    margin-bottom: 20px;
                }
                h2 {
                    @include mixin_font(26, 32, -10, bold);
                }
                h3 {
                    @include mixin_font(28, 32, -10, bold);
                }
                h4 {
                    @include mixin_font(19, 26.5, -10, bold);
                }
                .ltx_Bio {
                    & > p {
                        @include mixin_font(19, 26.5, -10, bold);
                    }
                    @apply mb-8;
                }
                .ltx_QuestionHolder {
                    // border: 1px solid red;
                    @apply flex flex-row mb-3;
                    margin-bottom: 0.66rem;
                    gap: 20px;
                    .ltx_Number {
                        color: white;
                        // background: var(--c-blue-xlight);
                        background: var(--c-b-blue);
                        font-size: 3.4rem;
                        font-weight: 600;
                        height: 63px;
                        width: 63px;
                        flex-grow: 0;
                        text-align: center;
                        border-radius: 100%;
                        aspect-ratio: 1;
                        // line-height: 1.4;
                        letter-spacing: -0.4rem;
                        padding-right: 4px;
                        padding-top: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    .ltx_Question {
                        @apply mt-3 uppercase;
                        color: var(--c-b-blue);
                        @include mixin_font(15, 21, -10, bold);
                    }
                    .ltx_Answer {
                        @apply mt-0;
                        @include mixin_font(16, 21, -10, regular);
                        p {
                            font-size: 16px;
                        }
                    }
                    &:nth-of-type(2) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber1.svg");
                    }
                    &:nth-of-type(3) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber2.svg");
                    }
                    &:nth-of-type(4) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber3.svg");
                    }
                    &:nth-of-type(5) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber4.svg");
                    }
                    &:nth-of-type(6) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber5.svg");
                    }
                    &:nth-of-type(7) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber6.svg");
                    }
                    &:nth-of-type(8) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber7.svg");
                    }
                    &:nth-of-type(9) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber8.svg");
                    }
                    &:nth-of-type(10) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber9.svg");
                    }
                    &:nth-of-type(11) > .ltx_Number {
                        font-size: 0;
                        background: url("/creatorNumber10.svg");
                    }
                }

                blockquote {
                    @extend .txt_Quote;
                    margin: 0;
                    padding: 0;
                    color: #2e323a;
                    // border: 1px solid red;
                    position: relative;
                    margin-bottom: 35px;
                    &::before {
                        content: "”";
                        margin-left: -16px;
                        position: absolute;
                    }
                    // &::after {
                    //     content: "”";
                    //     display: inline;
                    // }
                    @include mixin_font(24, 34, -20, bold);
                    cite {
                        @include mixin_font(16, 19, 100, bold);
                        text-transform: uppercase;
                        font-style: normal;
                        margin-left: 15px;
                    }
                }

                blockquote > p {
                    @include mixin_font(24, 34, -20, bold);
                    margin: 0;
                    padding: 0;
                    display: inline;
                }
            }

            .ltx_Tags {
                margin-top: 55px;
                margin-bottom: 55px;
                span,
                a {
                    @extend .txt_ArticlesTag;
                    @apply py-2 px-6 mr-4 rounded-full;
                    color: var(--c-b-black);
                    display: inline-block;
                    background: var(--c-grey-light);
                    cursor: pointer;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    // margin-left: 10px;
                    &:hover {
                        background: var(--c-b-red);
                        color: white;
                    }
                }
            }
        }
        .ltx_RightColumn {
            flex-grow: 1;
            padding-top: 30px;
            // border: 1px solid red;
            background: url("/article_right_trapeze.svg") no-repeat left 80px;
            background-size: 570px;
            .ltx_Holder {
                @apply flex flex-col items-center;
                width: 415px;
                min-height: 1090px;
                // border: 1px solid grey;
                > .ltx_Title {
                    @include mixin_font(25, 28, -10, bold);
                    @apply text-center mb-4;
                    color: var(--c-b-red);
                }
                .ltx_Article {
                    width: 224px;
                    @apply flex flex-col items-center mb-7;
                    cursor: pointer;
                    .ltx_Image {
                        width: 224px;
                        height: 138px;
                        border-radius: 18px;
                        // background: grey;
                        background-size: cover;
                        background-position: center;
                        margin-bottom: 5px;
                    }
                    .ltx_Label {
                        @extend .ltx_Bar;
                        margin-bottom: 0;
                        margin-top: 0;
                        color: var(--c-b-blue);
                    }
                    .ltx_Title {
                        @include mixin_font(20, 24, -10, regular);
                        @apply text-center;
                    }
                    .ltx_Author {
                        @include mixin_font(11, 14, 150, bold);
                        @apply uppercase text-center mt-3 mb-2;
                    }
                    .ltx_ArrowLink {
                        @apply mx-auto;
                    }
                    &:hover {
                        .ltx_Title {
                            text-decoration: underline;
                        }
                        .ltx_ArrowLink i {
                            background-image: url("/icon_arrowlinkred.svg");
                        }
                    }
                }
            }
        }
    }
}
.ltx-CreatorFeatured {
    // border: 1px solid red;
    padding-bottom: 55px;
    display: block;
    .ltx_Container {
        border: 0;
    }
    .ltx_Holder {
        // background: var(--c-grad-yellowpurple-vertical);
        background: var(--c-blue-xlight);
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        @apply flex flex-col;
        .ltx_Label {
            @include mixin_font(29, 32, -20, bold);
            text-align: center;
            color: var(--c-b-red);
            margin-top: 43px;
            margin-bottom: 43px;
        }
    }
    .ltx_Grid {
        // border: 1px solid red;
        display: grid;
        gap: 20px;
        row-gap: 40px;
        grid-template-columns: 1fr 1fr;
        .ltx_ArticleBox {
            width: 100%;
            @apply flex flex-row;
            margin: 0;
            > div:first-of-type {
                flex-shrink: 0;
            }
            .ltx_Image {
                height: 188px;
                aspect-ratio: 1;
                width: 188px;
            }
            .ltx_Label {
                @extend .ltx_Bar;
                color: var(--c-b-blue);
                margin: 0px;
            }
            .ltx_Title {
                @apply my-3 px-6;
            }
            > div:nth-of-type(2) {
                // border: 1px solid red;
                @apply flex flex-col items-center justify-center;
            }
        }
    }
}

.ltx-Sitemap,
.ltx-Terms,
.ltx-404 {
    @apply p-16;
    h1 {
        @extend .txt_Header2;
    }
    h2 {
        @extend .txt_Header4;
        @apply mt-8 mb-4;
        color: var(--c-b-red);
        text-decoration: true;
        &:hover {
            text-decoration: none;
        }
    }
    a {
        @extend .txt_Header5;
        color: var(--c-b-red);
        text-decoration: underline;
        display: block;
        &:hover {
            text-decoration: none;
        }
    }
    p {
        @apply mb-4;
        @extend .txt_BodyCopy3;
    }
}
.ltx-Terms {
    padding: 4rem 20vw;
}

.ltx-SearchBar {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    align-items: flex-start;
    padding-top: 10vh;

    .ltx_Container {
        border: none;
        width: 70vw;
        padding: 10px;
        background: white;
        box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.5);
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        position: relative;
        input {
            width: 100%;
            border-radius: 5px;
            font-size: 1rem;
            padding: 10px;
            &:focus-visible {
                outline: none;
            }
        }
        &.ltx_Loading::after {
            content: "";
            position: absolute;
            right: 15px;
            top: 18px;
            width: 30px;
            height: 30px;
            background: url("/loading.svg") center no-repeat;
            background-size: contain;
        }
        &.ltx_Loading input {
            opacity: 0.5;
            pointer-events: none;
        }
        .ltx_Results {
            border-top: 1px solid var(--c-blue-light);
            margin-top: 10px;
            padding: 10px;
            font-size: 12px;
            line-height: 2;
            max-height: 70vh;
            overflow-y: auto;
            strong {
                display: block;
                @include mixin_font(16, 24, -10, bold);
            }
            strong:not(:first-of-type) {
                margin-top: 20px;
            }
            a {
                @include mixin_font(14, 24, -10, regular);
                display: flex;
                justify-content: space-between;
                &:hover {
                    text-decoration: underline;
                }
                span {
                    color: var(--c-b-red);
                    text-align: right;
                }
            }
            h3 {
                font-style: italic;
                color: var(--c-b-red);
                padding: 10px 0;
            }
        }
    }
}

.ltx-404 {
    text-align: center;
    padding-top: 1.5rem;
    img {
        max-height: 50vh;
        max-width: 50vh;
    }
    button {
        display: inline-block;
        width: 198px;
        height: 46px;
        border-radius: 23px;
        @include mixin_font(17, 43, 0, bold);
        background: var(--c-b-red);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: white;
        padding-top: 3px;
        &:hover {
            background: var(--c-b-darkgrey);
        }
    }
    h2 {
        @include mixin_font(32, 52, -20, bold);
        color: var(--c-b-dark);
    }
    .ltx_SuggestedArticles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > div {
            min-width: 350px;
            display: flex;
            flex-direction: row;
            > div:first-child {
                .ltx_Image {
                    width: 121px;
                    height: 121px;
                    float: left;
                }
            }
            > div:nth-child(2) {
                @apply pl-4 text-left;
                @include mixin_font(20, 24, -10, regular);

                span {
                    @include mixin_font(15, 24, 150, bold);
                    color: var(--c-b-blue);
                    text-transform: uppercase;

                    display: block;
                }
            }
            &:hover {
                > div:nth-child(2) {
                    div {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.ltx-AboutUs {
    padding-top: 19px;
    > .ltx_Container {
        border: 0;
        &:first-of-type {
            > div:first-of-type {
                padding-top: 112px;
                .ltx_Label {
                    background: #4cc7e1;
                    height: 32px;
                    @include mixin_font(15, 24, 150, bold);
                    white-space: nowrap;
                    display: inline-flex;
                    padding: 0 28px;
                    align-items: center;
                    margin-bottom: 28px;
                }
                h1 {
                    @include mixin_font(46, 48, -10, regular);
                    margin-bottom: 17px;
                }
                h3 {
                    @include mixin_font(28, 36, -10, regular);
                }
            }
            > div:last-of-type {
                flex-shrink: 0;
            }
        }
        &:not(:first-of-type) {
            flex-direction: column;
            @include mixin_font(24, 30, -10, regular);
            p {
                margin-bottom: 35px;
            }
            ul {
                margin-bottom: 35px;
                list-style: disc;
                list-style-position: outside;
                padding-left: 22px;
            }
            h3 {
                @include mixin_font(28, 30, -10, bold);
                text-transform: uppercase;
                margin-bottom: 42px;
            }
        }
    }
    .ltx_LatestStories {
        background: #c9f2fb;
        padding: 60px 0;
        text-align: center;
        .ltx_Container {
            flex-direction: column;
            border: 0;
            h2 {
                @include mixin_font(32, 52, -20, bold);
                color: var(--c-b-dark);
            }
            .ltx_SuggestedArticles {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                > div {
                    min-width: 350px;
                    display: flex;
                    flex-direction: row;
                    background: transparent;
                    > div:first-child {
                        .ltx_Image {
                            width: 121px;
                            height: 121px;
                            float: left;
                        }
                    }
                    > div:nth-child(2) {
                        @apply pl-4 text-left;
                        @include mixin_font(20, 24, -10, regular);

                        span {
                            @include mixin_font(15, 24, 150, bold);
                            color: var(--c-b-blue);
                            text-transform: uppercase;

                            display: block;
                        }
                    }
                    &:hover {
                        > div:nth-child(2) {
                            div {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}
// Temporary hiding
.ltx-ZoneThreeColumns
    > div:first-of-type
    .ltx_zonethreecols_LeftArticle
    > .ltx_Announce,
.ltx-ZoneThreeColumns
    > div:last-of-type
    .ltx_zonethreecols_RightArticle
    > .ltx_Announce {
    display: none;
}
