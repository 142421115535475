@import "globals";

// Article Styles
// style_One
.ltx-Article.style_One {
    $accentColor: #c9f2fb;
    // border: 1px dotted green;
    .ltx-ArticleHeader {
        background-color: $accentColor !important;
        .ltx_LeftColumn {
            background-color: $accentColor !important;
        }
    }
    .ltx-ArticleBody .ltx_Container .ltx_RightColumn {
        padding-top: 150px;
        background-size: auto 1227px;
        background-position: left 30px;
    }
    .ltx-ArticleFooter .ltx_Container .ltx_Holder {
        background: $accentColor !important;
    }
}
// style_Two
.ltx-Article.style_Two {
    // border: 1px dotted blue;
    $accentColor: #fff9c1;
    .ltx-ArticleHeader {
        background-color: $accentColor !important;
    }
    .ltx-ArticleHeader .ltx_LeftColumn {
        background: $accentColor !important;
    }
    .ltx-ArticleHeader .ltx_LeftColumn .ltx_Label {
        background: #aee5f1;
    }
    .ltx-ArticleBody .ltx_Container .ltx_RightColumn {
        padding-top: 120px;
        background-image: url("/article_style2_rightBackground.svg");
        background-position-y: 72px;
        background-size: auto 1182px;
    }
    .ltx-ArticleFooter .ltx_Container .ltx_Holder {
        background: $accentColor !important;
    }
}
// style_Three
.ltx-Article.style_Three {
    $accentColor: #ffebf3;
    // border: 1px dotted purple;
    .ltx-ArticleHeader {
        background-color: $accentColor !important;
    }
    .ltx-ArticleHeader .ltx_LeftColumn {
        background-color: $accentColor !important;
    }
    .ltx-ArticleHeader .ltx_LeftColumn .ltx_Label {
        background: #fff180;
    }
    .ltx-ArticleBody .ltx_Container .ltx_RightColumn {
        padding-top: 120px;
        background-image: url("/article_style3_rightBackground.svg");
        background-position-y: 30px;
        background-size: auto 1064px;
    }
    .ltx-ArticleFooter .ltx_Container .ltx_Holder {
        background: $accentColor !important;
    }
}

.editorial_Label {
    margin-bottom: 16px !important;
}
// New Editorial style
.ltx-Article.style_Four {
    .wideImage {
        display: flex;
        justify-content: center;
        position: relative;
        padding-top: 30px;
        > img {
            width: 100vw;
            max-width: 1685px;
            z-index: 2;
        }
    }

    display: flex;
    flex-direction: column;

    $mainPageHeight: 74.1145833333333vw;
    $mainPageWidth: 883px;
    $mainPageHalfWidth: 441px;
    .ltx-ArticleHeader {
        // border: 1px solid green;
        background-color: #fdd2e5 !important;
        padding-top: unset;
        flex-direction: column;
        position: relative;
        height: unset !important;
        padding-top: 0 !important;
        .ltx_Container {
            max-width: unset;
            background-color: #fdd2e5;
            // border: 2px solid red;
            padding: 0;
            // width: $mainPageWidth;
            position: relative;
            display: flex;
            flex-direction: column;
            height: unset;
            width: 883px;
            margin: 0 auto;
            z-index: 2;

            img {
                width: 100vw;
                max-width: 1685px;
                z-index: 4;
                align-self: center;
                margin-bottom: 107px;
            }

            .ltx_Label {
                @extend .txt_SectionLabel;
                @apply uppercase pt-1 pb-1 px-7;
                color: white;
                background-color: #aa4495;
                margin-bottom: 9px;
                align-self: flex-start;
                @extend .editorial_Label;
            }
            h1 {
                @include mixin_font(46, 58, -20, bold);
                margin-bottom: 1rem;
                margin-top: 1rem;
            }
            h3 {
                @include mixin_font(32, 40, -20, bold);
                -webkit-line-clamp: unset;
                line-clamp: unset;
                width: 100%;
                margin-bottom: 22px;
            }
        }
        &::after {
            content: "";
            // border: 1px solid red;
            display: block;
            width: 100vw;
            z-index: 1;
            // top: calc(100% - 30px);
            // height: max(calc(1388px + 71vw), 2830px);
            background: #fdd2e5;
            position: absolute;
            z-index: 1;
            top: 100%;
            height: min(1180px);
        }
    }

    .ltx-ArticleBody {
        // display: none;
        width: $mainPageWidth;
        padding: 0;
        margin: 0 auto;
        // margin-top: -290px;
        // border: 1px solid blue;
        .ltx_Container {
            margin: 0;
            padding: 0;
            .ltx_LeftColumn {
                // border: 1px solid greenyellow;
                margin: 0;
                padding: 0;
                width: 100%;
                img {
                    width: 100%;
                    z-index: 2;
                    position: relative;
                }
                p {
                    @include mixin_font(24, 32, -20, regular);
                    z-index: 100;
                    position: relative;
                    margin-bottom: 13px;
                }
                h2,
                h4 {
                    z-index: 2;
                    position: relative;
                }

                figure {
                    margin-bottom: 52px;
                }
                figure:nth-of-type(1) {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    padding-top: 10px;
                    > img {
                        width: 100vw;
                        max-width: 1685px;
                        z-index: 2;
                    }
                    &::after {
                        content: "";
                        // border: 1px solid red;
                        display: block;
                        width: 100vw;
                        z-index: 1;
                        top: -1580px;
                        height: 1790px;
                        background-color: #fdd2e5;
                        position: absolute;
                        z-index: 1;
                    }
                }
                figure:nth-of-type(2) {
                    position: relative;
                    // border: 2px solid red;
                    // margin-bottom: 60px;
                }
                figure:nth-of-type(3) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    padding-top: 50px;
                    padding-bottom: 30px;
                    > img {
                        width: 100vw;
                        max-width: 100vw;
                    }
                    // &::after {
                    //     content: "";
                    //     // border: 1px solid red;
                    //     display: block;
                    //     width: 100vw;
                    //     z-index: 1;
                    //     top: calc(100% - 30px);
                    //     height: max(calc(1388px + 71vw), 2830px);
                    //     background: var(--c-grad-pink-vertical);
                    //     position: absolute;
                    //     z-index: 1;
                    //     max-height: 2830px;
                    //     min-height: 2730px;
                    // }
                }
                figure:nth-of-type(4) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    > img {
                        width: 100vw;
                        max-width: 1550px;
                    }
                }

                figure:nth-of-type(5) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    padding-top: 10px;
                    margin-top: 0px;
                    // margin-bottom: -50px;
                    > img {
                        width: 100vw;
                        max-width: 1292px;
                        z-index: 2;
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 100vw;
                        height: 50vh;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        background: white;
                    }
                }
                figure:nth-of-type(6) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    > img {
                        width: 100vw;
                        max-width: 100vw;
                        // margin-bottom: -170px;
                    }
                    // &::after {
                    //     content: "";
                    //     // border: 1px solid red;
                    //     display: block;
                    //     width: 100vw;
                    //     top: calc(100% - 245px);
                    //     height: 4080px;
                    //     background: var(--c-grad-yellowpurple-vertical);
                    //     position: absolute;
                    //     z-index: 1;
                    // }
                }

                figure:nth-of-type(7) {
                    position: relative;
                    // border: 2px solid red;
                    display: flex;
                    justify-content: center;
                    margin-top: 90px;
                    > img {
                        width: 100vw;
                        max-width: 1550px;
                    }
                }

                h2,
                h4 {
                    @include mixin_font(46, 58, -20, bold);
                    color: #ff504e;
                    margin-top: 0px;
                    margin-bottom: 10px;
                    z-index: 999;
                    span {
                        color: var(--c-b-pink) !important;
                    }
                    a {
                        margin-top: 20px;
                    }
                }

                h3 {
                    @include mixin_font(39, 58, -20, bold);
                }

                ul {
                    list-style: disc;
                    margin-bottom: 20px;
                    list-style-position: outside !important;
                    padding-left: 20px;
                    li {
                        @include mixin_font(24, 32, -20, bold);
                        color: #aa4495;
                        list-style-position: outside;
                        margin-bottom: 22px;
                        &::marker {
                            color: #aa4495;
                        }
                    }
                }

                blockquote {
                    margin: 60px 0;
                    @include mixin_font(52, 58, -20, bold);
                    > p {
                        @include mixin_font(52, 58, -20, bold);
                        color: #6668a6;
                    }
                    color: #6668a6;
                    &::before,
                    &::after {
                        display: none;
                    }
                }

                ol {
                    background-color: var(--c-blue-xlight);
                    position: relative;
                    margin-top: 63px;
                    margin-bottom: 70px;
                    li {
                        @include mixin_font(28, 32, -20, bold);
                        z-index: 2;
                        position: relative;
                        margin-bottom: 5px;
                        a {
                            @include mixin_font(28, 32, -20, bold);
                            color: var(--c-b-darkgrey) !important;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &::before {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 51px;
                            height: 51px;
                            padding-top: 6px;
                            @include mixin_font(32, 32, -20, bold);
                            color: white;
                            background-color: #6668a6;
                            background-image: none;
                        }
                    }
                }

                div.raw-html-embed {
                    @extend .wideImage;
                    margin-bottom: 52px;
                    > iframe {
                        width: 100vw;
                        max-width: 1685px;
                        z-index: 2;
                    }
                    blockquote {
                        margin-bottom: 0;
                    }
                }

                .ltx_BodyContainer {
                    padding-bottom: 100px;
                }
            }
        }
    }

    // Hiding some elements
    .ltx-ArticleBody .ltx_Container .ltx_LeftColumn > hr,
    .ltx-ArticleBody .ltx_Container .ltx_RightColumn,
    .ltx-ArticleBody .ltx_Container .ltx_LeftColumn .ltx_Tags,
    .ltx-ArticleFooter {
        display: none;
    }
}

// New Dancer style
.ltx-Article.style_Five {
    // border: 10px solid red;

    .wideImage {
        display: flex;
        justify-content: center;
        position: relative;
        padding-top: 30px;
        > img {
            width: 100vw;
            max-width: 1685px;
            z-index: 2;
        }
    }

    display: flex;
    flex-direction: column;

    $mainPageHeight: 74.1145833333333vw;
    $mainPageWidth: 883px;
    $mainPageHalfWidth: 441px;

    .ltx-ArticleHeader {
        // border: 2px solid green;
        flex-direction: column;
        position: relative;
        height: unset;
        .ltx_Container {
            max-width: unset;
            background-color: var(--c-blue-xlight);
            // border: 2px solid red;
            padding: 0;
            // width: $mainPageWidth;
            position: relative;
            display: flex;
            flex-direction: column;
            height: unset;
            width: 883px;
            margin: 0 auto;
            z-index: 2;

            img {
                width: 100vw;
                max-width: 1685px;
                z-index: 4;
                align-self: center;
                margin-bottom: 107px;
            }
            .ltx_Label {
                @extend .txt_SectionLabel;
                @apply uppercase pt-1 pb-1 px-7;
                background-color: var(--c-b-yellow);
                color: var(--c-b-darkgrey);
                margin-bottom: 9px;
                align-self: flex-start;
                @extend .editorial_Label;
            }
            h1 {
                @include mixin_font(46, 58, -20, bold);
                margin-bottom: 1rem;
                margin-top: 1rem;
            }
            h3 {
                @include mixin_font(32, 40, -20, bold);
                -webkit-line-clamp: unset;
                line-clamp: unset;
                width: 100%;
                margin-bottom: 22px;
            }
        }
        &::after {
            content: "";
            // border: 1px solid red;
            display: block;
            width: 100vw;
            z-index: 1;
            // top: calc(100% - 30px);
            // height: max(calc(1388px + 71vw), 2830px);
            background: var(--c-blue-xlight);
            position: absolute;
            z-index: 1;
            top: 100%;
            height: min(1180px);
        }
    }

    .ltx-ArticleBody {
        // display: none;
        width: $mainPageWidth;
        padding: 0;
        margin: 0 auto;
        // margin-top: -290px;
        // border: 1px solid blue;
        .ltx_Container {
            margin: 0;
            padding: 0;
            .ltx_LeftColumn {
                // border: 1px solid greenyellow;
                margin: 0;
                padding: 0;
                width: 100%;
                img {
                    width: 100%;
                    z-index: 2;
                    position: relative;
                }
                p {
                    @include mixin_font(24, 28, -20, regular);
                    z-index: 100;
                    position: relative;
                    margin-bottom: 13px;
                }
                h2,
                h4 {
                    z-index: 2;
                    position: relative;
                }

                figure {
                    margin-bottom: 52px;
                }
                figure:nth-of-type(1) {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    padding-top: 30px;
                    margin-top: 100px;
                    > img {
                        width: 100vw;
                        max-width: 1685px;
                        z-index: 4;
                    }
                    &::before {
                        content: "";
                        // border: 1px solid red;
                        display: block;
                        width: 100vw;
                        top: -50px;
                        height: calc(100% + 50px);
                        background-color: white;
                        position: absolute;
                        z-index: 3;
                    }
                }
                figure:nth-of-type(2) {
                    @extend .wideImage;
                }
                figure:nth-of-type(3) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    // padding-top: 50px;
                    padding-bottom: 30px;
                    > img {
                        width: 100vw;
                        max-width: 100vw;
                    }
                    &::after {
                        content: "";
                        // border: 1px solid red;
                        display: block;
                        width: 100vw;
                        z-index: 1;
                        top: calc(100% - 30px);
                        height: max(calc(1388px + 64vw), 1530px);
                        background: var(--c-blue-xlight);
                        position: absolute;
                        z-index: 1;
                        max-height: 2830px;
                        min-height: 1730px;
                    }
                }
                figure:nth-of-type(4) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    margin-top: 100px;
                    > img {
                        width: 100vw;
                        max-width: 1550px;
                        z-index: 4;
                    }
                    &::before {
                        content: "";
                        // border: 1px solid red;
                        display: block;
                        width: 100vw;
                        top: -50px;
                        height: calc(100% + 570px);
                        background-color: white;
                        position: absolute;
                        z-index: 3;
                    }
                }

                figure:nth-of-type(5) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    padding-top: 50px;
                    margin-top: 70px;
                    margin-bottom: -50px;
                    > img {
                        width: 100vw;
                        max-width: 1292px;
                        z-index: 2;
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 100vw;
                        height: 50vh;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        background: white;
                    }
                }
                figure:nth-of-type(6) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    > img {
                        width: 100vw;
                        max-width: 100vw;
                        margin-bottom: -170px;
                    }
                    &::after {
                        content: "";
                        // border: 1px solid red;
                        display: block;
                        width: 100vw;
                        top: calc(100% - 245px);
                        height: 4080px;
                        background: var(--c-grad-yellowpurple-vertical);
                        position: absolute;
                        z-index: 1;
                    }
                }

                figure:nth-of-type(7) {
                    position: relative;
                    // border: 2px solid red;
                    display: flex;
                    justify-content: center;
                    margin-top: 90px;
                    > img {
                        width: 100vw;
                        max-width: 1550px;
                    }
                }

                h2,
                h4 {
                    @include mixin_font(46, 58, -20, bold);
                    color: var(--c-b-pink) !important;
                    margin-top: 0px;
                    margin-bottom: 10px;
                    z-index: 999;
                    span {
                        color: var(--c-b-pink) !important;
                    }
                    a {
                        margin-top: 20px;
                    }
                }

                h3 {
                    @include mixin_font(39, 58, -20, bold);
                }

                ul {
                    list-style: disc;
                    margin-bottom: 20px;
                    list-style-position: outside !important;
                    padding-left: 20px;
                    li {
                        @include mixin_font(28, 32, -20, bold);
                        // color: #aa4495;
                        list-style-position: outside;
                        margin-bottom: 22px;
                        &::marker {
                            color: var(--c-b-yellow);
                        }
                    }
                }

                blockquote {
                    margin: 60px 0;
                    @include mixin_font(52, 58, -20, bold);
                    > p {
                        @include mixin_font(52, 58, -20, bold);
                        color: #aa4495;
                    }
                    color: #aa4495;
                    &::before,
                    &::after {
                        display: none;
                    }
                }

                ol {
                    background-color: var(--c-blue-xlight);
                    position: relative;
                    margin-top: 63px;
                    margin-bottom: 70px;
                    li {
                        @include mixin_font(28, 32, -20, bold);
                        z-index: 2;
                        position: relative;
                        margin-bottom: 5px;
                        a {
                            @include mixin_font(28, 32, -20, bold);
                            color: var(--c-b-darkgrey) !important;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &::before {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 51px;
                            height: 51px;
                            padding-top: 6px;
                            @include mixin_font(32, 32, -20, bold);
                            color: var(--c-b-darkgrey);
                            background-color: var(--c-b-yellow);
                            background-image: none;
                        }
                    }
                }

                div.raw-html-embed {
                    margin-bottom: 52px;
                    @extend .wideImage;
                    > iframe {
                        width: 100vw;
                        max-width: 1685px;
                        z-index: 2;
                    }
                    > blockquote {
                        margin-bottom: 0;
                    }
                }

                .ltx_BodyContainer {
                    padding-bottom: 100px;
                }
            }
        }
    }

    // Hiding some elements
    .ltx-ArticleBody .ltx_Container .ltx_LeftColumn > hr,
    .ltx-ArticleBody .ltx_Container .ltx_RightColumn,
    .ltx-ArticleBody .ltx_Container .ltx_LeftColumn .ltx_Tags,
    .ltx-ArticleFooter {
        display: none;
    }
}

// ---------------------------------------------------------

// Tablet
@media only screen and (min-width: 640px) and (max-width: 1200px) {
    .ltx-Article.style_Four,
    .ltx-Article.style_Five {
        .ltx-ArticleHeader {
            .ltx_Container {
                width: calc(100% - 96px);
                .ltx_LeftColumn h1 {
                    line-height: 48px;
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }
        }
        .ltx-ArticleBody {
            width: 100%;
            padding-left: 48px;
            padding-right: 48px;
            margin-top: -240px !important;

            .ltx_Container .ltx_LeftColumn figure:nth-of-type(6) {
                > img {
                    margin-bottom: -70px;
                }
                &::after {
                    top: calc(100% - 125px);
                }
            }
        }
    }
    .ltx-Article.style_Five {
        .ltx-ArticleHeader {
            margin-bottom: unset !important;
            &::after {
                height: min(1430px, 254vh);
            }
        }
        .ltx-ArticleBody {
            margin-top: unset !important;
            .ltx_Container .ltx_LeftColumn {
                figure:nth-of-type(3) {
                    &::after {
                        height: max(calc(1388px + 85vw), 1530px);
                    }
                }
            }
        }
    }
}
// Mobile
@media only screen and (max-width: 640px) {
    .ltx-Article.style_Four,
    .ltx-Article.style_Five {
        // border: 2px solid green;
        .ltx-ArticleHeader {
            margin-bottom: min(200vw, 840px);
            .ltx_Container {
                padding-top: 16px;
                width: calc(100% - 60px);
                .ltx_LeftColumn {
                    h1 {
                        font-size: 36px;
                        line-height: 42px;
                        margin-bottom: 40px;
                    }
                    h3 {
                        font-size: 26px;
                        line-height: 36px;
                    }
                }
            }
        }
        .ltx-ArticleBody {
            // border: 1px solid blue;
            width: 100vw;
            padding: 0 30px;
            margin-top: -53vw;

            .ltx_Container .ltx_LeftColumn {
                p,
                ul > li {
                    font-size: 20px;
                    line-height: 30px;
                }
                h2 {
                    font-size: 36px;
                    line-height: 48px;
                    margin-top: 40px;
                }
                blockquote,
                blockquote > p {
                    font-size: 32px;
                    line-height: 38px;
                }
                figure:nth-of-type(6) {
                    > img {
                        margin-bottom: -30px;
                    }
                    &::after {
                        top: calc(100% - 95px);
                        height: 4310px;
                    }
                }
            }
        }
    }

    .ltx-Article.style_Five {
        .ltx-ArticleHeader {
            margin-bottom: unset !important;
            &::after {
                height: min(1430px, 254vh);
            }
        }
        .ltx-ArticleBody {
            margin-top: unset !important;
            .ltx_Container .ltx_LeftColumn {
                figure:nth-of-type(3) {
                    &::after {
                        height: max(calc(1388px + 404vw), 1530px);
                    }
                }
                figure:nth-of-type(4) {
                    &::before {
                        height: calc(100% + 100vw);
                    }
                }
            }
        }
    }
}
